import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

import ViewsList from './atoms/ViewsList';

import { Integration } from '../../../interfaces/Integration';
import { View } from '../../../interfaces/View';

import { useViews } from '../../../queries/getters/useViews';
import { useSingleIntegrationMutation } from '../../../queries/mutations/useSingleIntegrationMutation';


/* --------
 * Helpers
 * -------- */
function not(left: View[], right: View[]): View[] {
  return left.filter((view) => !right.find((v) => v._id === view._id));
}


/* --------
 * Styles
 * -------- */
const useStyles = makeStyles((theme) => ({
  root      : {
    margin: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  button    : {
    margin: theme.spacing(0.5, 0)
  },
  actions   : {
    paddingTop: theme.spacing(8)
  }
}));


/* --------
 * Component Interfaces
 * -------- */
export interface MenuTabProps {
  /** The entire integration object */
  integration: Integration;
}


/* --------
 * Component Definition
 * -------- */
const MenuTab: React.FunctionComponent<MenuTabProps> = (props) => {

  const {
    integration
  } = props;

  const classes = useStyles();


  // ----
  // Internal States
  // ----
  const [ leftSelected, setLeftSelected ] = React.useState<View[]>([]);
  const [ rightSelected, setRightSelected ] = React.useState<View[]>([]);


  // ----
  // Queries & Mutations
  // ----
  const views = useViews();
  const mutateIntegration = useSingleIntegrationMutation(integration);


  // ----
  // Handlers
  // ----
  const updateIntegrationMenu = React.useCallback(
    (newMenu: View[]) => {
      mutateIntegration.mutate({
        action: 'PATCH',
        path  : 'menu',
        data  : newMenu
      });
    },
    [ mutateIntegration ]
  );

  const handleAddViews = React.useCallback(
    () => {
      /** Check at least one view has been selected */
      if (rightSelected.length === 0) {
        return;
      }

      /** Build the new Views Menu */
      const newViews: View[] = integration.menu.slice();

      rightSelected.forEach((newView) => {
        if (!newViews.find((view) => view._id === newView._id)) {
          newViews.push(newView);
        }
      });

      /** Update the Menu */
      updateIntegrationMenu(newViews);

      /** Remove selected */
      setRightSelected([]);
    },
    [ rightSelected, integration.menu, updateIntegrationMenu ]
  );

  const handleRemoveViews = React.useCallback(
    () => {
      /** Check at least one view has been selected */
      if (leftSelected.length === 0) {
        return;
      }

      const newViews: View[] = integration.menu.filter((menu) => (
        !leftSelected.find((view) => view._id === menu._id)
      ));

      /** Update the Menu */
      updateIntegrationMenu(newViews);

      /** Remove Selected */
      setLeftSelected([]);
    },
    [ integration.menu, leftSelected, updateIntegrationMenu ]
  );


  // ----
  // Internal Data
  // ----
  const selectableViews = not(views.data || [], integration.menu);


  // ----
  // Component Render
  // ----
  if (!views.data) {
    return (
      <Box p={4}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} justify={'center'} alignItems={'flex-start'}>
      <Grid item xs>
        <ViewsList
          selected={leftSelected}
          title={'Voci Attive'}
          views={integration.menu}
          onSelectedChange={setLeftSelected}
        />
      </Grid>
      <Grid item>
        <Grid container direction={'column'} className={classes.actions}>
          <Button
            variant={'outlined'}
            size={'small'}
            className={classes.button}
            disabled={leftSelected.length === 0}
            onClick={handleRemoveViews}
          >
            &gt;
          </Button>
          <Button
            variant={'outlined'}
            size={'small'}
            className={classes.button}
            disabled={rightSelected.length === 0}
            onClick={handleAddViews}
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs>
        <ViewsList
          selected={rightSelected}
          title={'Voci Disponibili'}
          views={selectableViews}
          onSelectedChange={setRightSelected}
        />
      </Grid>
    </Grid>
  );
};

MenuTab.displayName = 'MenuTab';

export default MenuTab;
