import * as React from 'react';

import { PageHeader } from '../../components/PageHeader';
import { ManageAPIKey } from '../../dialogs/ManageAPIKey';

import { ApiKeysTable } from './components/ApiKeysTable';


export const ApiKeysPage: React.VFC = () => {

  return (
    <React.Fragment>
      <PageHeader
        content={'API Keys'}
        subtitle={'Gestisci le Chiavi di Autenticazione utilizzabili per accedere alle API'}
        action={(
          <ManageAPIKey
            buttonProps={{
              content: 'Crea Chiave',
              color  : 'primary',
              variant: 'contained'
            }}
          />
        )}
      />

      <ApiKeysTable />
    </React.Fragment>
  );
};
