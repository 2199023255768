import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { PageHeader } from '../../components/PageHeader';
import { ask } from '../../dialogs/Confirm';

import { useSingleIntegration } from '../../queries/getters/useSingleIntegration';
import { useIntegrationsMutation } from '../../queries/mutations/useIntegrationsMutation';

import CalendarsTab from './Calendars';
import GeneralTab from './General';
import MenuTab from './Menu';
import ServicesTab from './Services';
import WasteTypesTab from './WasteTypes';


/* --------
 * Component Definition
 * -------- */
export const DistrictSingle: React.VFC = () => {

  // ----
  // Internal State
  // ----
  const [ currentTab, setCurrentTab ] = React.useState<number>(0);


  // ----
  // Get District Integration Data
  // ----
  const history = useHistory();
  const { _id } = useParams<{ _id: string }>();
  const integration = useSingleIntegration(_id);
  const mutateIntegrations = useIntegrationsMutation();


  // ----
  // Handlers
  // ----
  const handleTabChange = React.useCallback(
    (event: React.ChangeEvent<{}>, newTab: number) => {
      setCurrentTab(newTab);
    },
    []
  );

  const handleDeleteDistrict = React.useCallback(
    async () => {
      if (!integration.data) {
        return;
      }

      const result = await (
        ask(
          'Rimuovi Integrazione',
          [
            `Vuoi davvero rimuovere l'integrazione con il comune ${integration.data.name}.`,
            'L\'operazione è irreversibile e tutti i dati collegati a questo comune verranno cancellati.'
          ].join(' ')
        )
      );

      if (result) {
        mutateIntegrations.mutate({ action: 'DELETE', data: integration.data });
        history.push('/districts');
      }

    },
    [ history, integration.data, mutateIntegrations ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      <PageHeader
        backButton
        queryStatus={integration.status}
        content={integration.data?.name}
        subtitle={`Codice Comune ${integration.data?.districtCode} su ${integration.data?.yardServer}`}
        action={(
          <Button variant={'contained'} color={'secondary'} onClick={handleDeleteDistrict}>Rimuovi</Button>
        )}
      />

      <Box mb={3}>
        <Paper>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label={'Generali'} />
            <Tab label={'Menu'} />
            <Tab label={'Servizi'} />
            <Tab label={'Tipi Rifiuto'} />
            <Tab label={'Calendario'} />
          </Tabs>
        </Paper>
      </Box>

      {!integration.data && (
        <Paper>
          <Box p={4}>
            <LinearProgress />
          </Box>
        </Paper>
      )}

      {integration.data && (
        <React.Fragment>
          {currentTab === 0 && (<GeneralTab integration={integration.data} />)}
          {currentTab === 1 && (<MenuTab integration={integration.data} />)}
          {currentTab === 2 && (<ServicesTab integration={integration.data} />)}
          {currentTab === 3 && (<WasteTypesTab integration={integration.data} />)}
          {currentTab === 4 && (<CalendarsTab integration={integration.data} />)}
        </React.Fragment>
      )}

    </React.Fragment>
  );

};
