import { useQuery } from 'react-query';

import { GET_VIEWS } from '../keys';
import { useAxiosRequest } from '../lib/useAxiosRequest';

import { View } from '../../interfaces/View';


export function useViews() {
  const axiosRequestMaker = useAxiosRequest<View[]>({ url: '/views' });
  return useQuery(GET_VIEWS, () => axiosRequestMaker());
}
