import * as React from 'react';

import { useForm, Controller } from 'react-hook-form';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import { APIKey } from '../interfaces/APIKey';
import { useApiKeysMutation } from '../queries/mutations/useApiKeysMutation';


/* --------
 * Internal Types
 * -------- */
interface ManageAPIKeyProps {
  /** Trigger Button Props */
  buttonProps: ButtonProps & { content?: React.ReactText };

  /** An existing API Key */
  apiKey?: APIKey;
}


/* --------
 * Styling
 * -------- */
const useStyle = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  },
  form     : {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2)
    }
  }
}));


/* --------
 * Component Definition
 * -------- */
export const ManageAPIKey: React.VFC<ManageAPIKeyProps> = (props) => {

  const classes = useStyle();

  const {
    apiKey,
    buttonProps: {
      content: buttonContent,
      ...buttonProps
    }
  } = props;


  // ----
  // Hook Form & Mutations
  // ----
  const apiKeysMutation = useApiKeysMutation();
  const { control, handleSubmit, reset } = useForm<APIKey>();


  // ----
  // Internal State
  // ----
  const [ open, setOpen ] = React.useState<boolean>(false);


  // ----
  // Handlers
  // ----
  const handleOpenModal = React.useCallback(
    () => setOpen(true),
    []
  );

  const handleCloseModal = React.useCallback(
    () => {
      setOpen(false);
    },
    []
  );

  const handleResetForm = React.useCallback(
    () => {
      reset();
    },
    [ reset ]
  );

  const handleFormSubmit = React.useCallback(
    (data: Partial<APIKey>) => {
      if (apiKey) {
        apiKeysMutation.mutate({
          action: 'EDIT',
          data  : {
            ...apiKey,
            ...data
          }
        });
      }
      else {
        apiKeysMutation.mutate({ action: 'ADD', data });
      }
    },
    [ apiKey, apiKeysMutation ]
  );

  React.useEffect(
    () => {
      if (apiKeysMutation.status === 'success') {
        handleCloseModal();
      }
    },
    [ handleCloseModal, apiKeysMutation.status ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      {/* Trigger */}
      <Button {...buttonProps} onClick={handleOpenModal}>{buttonContent ?? 'Open Modal'}</Button>

      {/* Dialog with Form */}
      <Dialog fullWidth maxWidth={'xs'} open={open} onClose={handleCloseModal} onExited={handleResetForm}>
        <DialogTitle>{apiKey ? 'Gestisci' : 'Nuova'} Chiave API</DialogTitle>
        <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent>
            <DialogContentText>
              {'Inserisci il nome dell\'utilizzatore di questa Chiave'}
            </DialogContentText>
            <Controller
              name={'user'}
              control={control}
              defaultValue={apiKey?.user || ''}
              render={({ field }) => (
                <TextField
                  className={classes.fullWidth}
                  label={'Identificativo'}
                  {...field}
                />
              )}
            />
            <Controller
              name={'isEnabled'}
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled
                      checked={field.value}
                      {...field}
                    />
                  )}
                  label={'Chiave Attiva'}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} disabled={apiKeysMutation.isLoading}>
              Annulla
            </Button>
            <Button type={'submit'} variant={'contained'} color={'primary'} disabled={apiKeysMutation.isLoading}>
              {apiKey ? 'Modifica' : 'Aggiungi'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );

};
