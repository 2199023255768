import * as React from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


/* --------
 * Styles
 * -------- */
const useStyle = makeStyles((theme) => ({
  rootPaper: {
    margin : theme.spacing(-3, -3, 3, -3),
    padding: theme.spacing(3)
  }
}));


/* --------
 * Component Interfaces
 * -------- */
export interface PageHeaderProps {
  /** Action Element */
  action?: React.ReactElement;

  /** Add the BackButton */
  backButton?: boolean;

  /** Header main title */
  content?: string;

  /** Query status to variate header */
  queryStatus?: 'idle' | 'loading' | 'error' | 'success';

  /** Header subtitle */
  subtitle?: string;
}


/* --------
 * Component Definition
 * -------- */
export const PageHeader: React.FunctionComponent<PageHeaderProps> = (props) => {

  const {
    action,
    backButton,
    content,
    subtitle,
    queryStatus
  } = props;

  const classes = useStyle();


  // ----
  // Handlers
  // ----
  const { goBack: handleGoBack } = useHistory();


  // ----
  // Query Dependents Headers
  // ----
  if (typeof queryStatus === 'string') {
    if (queryStatus === 'idle' || queryStatus === 'loading') {
      return (
        <PageHeader
          backButton={backButton}
          content={'Caricamento...'}
          subtitle={typeof subtitle === 'string' ? 'Attendi il caricamento dei dati' : undefined}
        />
      );
    }

    if (queryStatus === 'error') {
      return (
        <PageHeader
          backButton={backButton}
          content={'Errore'}
          subtitle={typeof subtitle === 'string'
            ? 'Si è verificato un errore durante il caricamento dei dati'
            : undefined}
        />
      );
    }
  }


  // ----
  // Component Render
  // ----
  return (
    <Paper square className={classes.rootPaper}>
      <Grid container spacing={2} alignItems={'flex-end'}>
        {backButton && (
          <Grid item style={{ alignSelf: 'flex-start' }}>
            <IconButton color={'inherit'} onClick={handleGoBack}>
              <ArrowBackIosIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant={'h5'}>
            {content ?? 'Error'}
          </Typography>
          {subtitle && (
            <Typography variant={'subtitle1'} color={'textSecondary'}>
              {subtitle}
            </Typography>
          )}
        </Grid>
        {action && (
          <Grid item>
            {action}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

PageHeader.displayName = 'PageHeader';
