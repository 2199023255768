import * as React from 'react';
import { QueryKey, useQueryClient } from 'react-query';

import type { QueryState } from 'react-query/types/core/query';


/* --------
 * Types
 * -------- */
type CurrentQueryData<Data> = QueryState<Data> & {
  refetch: () => Promise<void>;
};


/* --------
 * Hook
 * -------- */
export function useCurrentQuery<Data>(name: string, key: QueryKey): CurrentQueryData<Data> {

  const queryClient = useQueryClient();

  const currentQueryData = queryClient.getQueryState<Data>(key);

  const refetchQuery = React.useCallback(
    async () => {
      await queryClient.refetchQueries(key);
    },
    [ queryClient, key ]
  );

  if (currentQueryData === undefined) {
    throw new Error(`${name}() hook must be used after parent query launch`);
  }

  return {
    ...currentQueryData,
    refetch: refetchQuery
  };

}
