import * as React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Detail from '../../../components/Detail';
import Panel from '../../../components/Panel';

import { Integration } from '../../../interfaces/Integration';
import { useSingleIntegrationMutation } from '../../../queries/mutations/useSingleIntegrationMutation';
import IntegrationLinks from './atoms/Links';


/* --------
 * Component Interfaces
 * -------- */
export interface GeneralTabProps {
  /** The entire integration object */
  integration: Integration;
}


/* --------
 * Component Definition
 * -------- */
const GeneralTab: React.FunctionComponent<GeneralTabProps> = (props) => {

  const {
    integration
  } = props;


  // ----
  // Mutations
  // ----
  const mutateIntegration = useSingleIntegrationMutation(integration);


  // ----
  // Handlers
  // ----
  const handleFieldChange = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const { value, name } = e.target;

      if (value !== integration[name as keyof Integration]) {
        mutateIntegration.mutate({
          action: 'PATCH',
          path  : name as string,
          data  : value
        });
      }
    },
    [ integration, mutateIntegration ]
  );

  const handleToggleEnable = React.useCallback(
    () => {
      mutateIntegration.mutate({
        action: 'PATCH',
        path  : 'isEnabled',
        data  : !integration.isEnabled
      });
    },
    [ integration.isEnabled, mutateIntegration ]
  );


  // ----
  // Component Render
  // ----
  return (
    <Grid container spacing={3}>

      <Grid item xs={12} md={6} lg={4}>
        <Panel header={'Proprietà'}>
          <Detail>
            <TextField
              fullWidth
              name={'name'}
              label={'Nome'}
              variant={'outlined'}
              defaultValue={integration.name}
              onBlur={handleFieldChange}
            />
          </Detail>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Detail>
                <TextField
                  fullWidth
                  name={'districtCode'}
                  label={'Codice'}
                  variant={'outlined'}
                  defaultValue={integration.districtCode}
                  onBlur={handleFieldChange}
                />
              </Detail>
            </Grid>
            <Grid item xs={8}>
              <Detail>
                <FormControl fullWidth>
                  <Select
                    value={integration.yardServer}
                    variant={'outlined'}
                    name={'yardServer'}
                    onChange={handleFieldChange}
                  >
                    <MenuItem value={'dbserv02'}>dbserv02.cantieridigitali.net</MenuItem>
                    <MenuItem value={'dbserv03'}>dbserv03.cantieridigitali.net</MenuItem>
                    <MenuItem value={'web02'}>web02.cantieridigitali.net</MenuItem>
                  </Select>
                </FormControl>
              </Detail>
            </Grid>
          </Grid>

          <Detail label={'Stato'}>
            <Typography>
              In questo momento, <b>{integration.name}</b> è{' '}
              {(integration.isEnabled ? <b>Attivato</b> : <b>Disattivato</b>)}{', '}
              pertanto {!integration.isEnabled && 'non'} sarà visualizzato nella lista{' '}
              dei comuni disponibili
            </Typography>
            <Box mt={2}>
              <Button
                variant={'contained'}
                color={integration.isEnabled ? 'primary' : 'secondary'}
                onClick={handleToggleEnable}
              >
                {integration.isEnabled ? 'Disattiva' : 'Attiva'} Comune
              </Button>
            </Box>
          </Detail>
        </Panel>
      </Grid>

      <Grid item xs={12} md={6} lg={8}>
        <IntegrationLinks
          integration={integration}
          linkPath={'systemLinks'}
          panelProps={{
            header: 'Link di Sistema'
          }}
        />

        <IntegrationLinks
          integration={integration}
          linkPath={'termsOfService'}
          panelProps={{
            header: 'Termini di Servizio'
          }}
        />

        <IntegrationLinks
          integration={integration}
          linkPath={'usefulLinks'}
          panelProps={{
            header: 'Link Utili'
          }}
        />
      </Grid>

    </Grid>
  );
};

GeneralTab.displayName = 'GeneralTab';

export default GeneralTab;
