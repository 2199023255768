import * as React from 'react';

import arraySort from 'array-sort';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

import Detail from '../../../components/Detail';
import Panel from '../../../components/Panel';

import EventTypesList from './atoms/EventTypesList';

import { Integration } from '../../../interfaces/Integration';
import { EventType } from '../../../interfaces/EventType';

import { useEventTypes } from '../../../queries/getters/useEventTypes';
import { useSingleIntegrationMutation } from '../../../queries/mutations/useSingleIntegrationMutation';


/* --------
 * Helpers
 * -------- */
function not(left: EventType[], right: EventType[]): EventType[] {
  return left.filter((eventType) => !right.find((e) => e._id === eventType._id));
}


/* --------
 * Styles
 * -------- */
const useStyles = makeStyles((theme) => ({
  root      : {
    margin: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  button    : {
    margin: theme.spacing(0.5, 0)
  },
  actions   : {
    paddingTop: theme.spacing(8)
  }
}));


/* --------
 * Component Interfaces
 * -------- */
export interface ServicesTabProps {
  /** The entire integration object */
  integration: Integration;
}


/* --------
 * Component Definition
 * -------- */
const ServicesTab: React.FunctionComponent<ServicesTabProps> = (props) => {

  const {
    integration
  } = props;

  const classes = useStyles();


  // ----
  // Internal States
  // ----
  const [ leftSelected, setLeftSelected ] = React.useState<EventType[]>([]);
  const [ rightSelected, setRightSelected ] = React.useState<EventType[]>([]);


  // ----
  // Queries & Mutations
  // ----
  const eventTypes = useEventTypes();
  const mutateIntegration = useSingleIntegrationMutation(integration);


  // ----
  // Handlers
  // ----
  const handleFieldChange = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const { value, name } = e.target;

      if (value !== integration[name as keyof Integration]) {
        mutateIntegration.mutate({
          action: 'PATCH',
          path  : name as string,
          data  : value
        });
      }
    },
    [ integration, mutateIntegration ]
  );

  const updateIntegrationEventTypes = React.useCallback(
    (newEventTypes: EventType[]) => {
      mutateIntegration.mutate({
        action: 'PATCH',
        path  : 'eventTypes',
        data  : newEventTypes
      });
    },
    [ mutateIntegration ]
  );

  const handleAddEventTypes = React.useCallback(
    () => {
      /** Check at least one view has been selected */
      if (rightSelected.length === 0) {
        return;
      }

      /** Build the new Views Menu */
      const newEventTypes: EventType[] = integration.eventTypes.slice();

      rightSelected.forEach((newEventType) => {
        if (!newEventTypes.find((eventType) => eventType._id === newEventType._id)) {
          newEventTypes.push(newEventType);
        }
      });

      /** Update the Menu */
      updateIntegrationEventTypes(newEventTypes);

      /** Remove selected */
      setRightSelected([]);
    },
    [ rightSelected, integration.eventTypes, updateIntegrationEventTypes ]
  );

  const handleRemoveEventTypes = React.useCallback(
    () => {
      /** Check at least one view has been selected */
      if (leftSelected.length === 0) {
        return;
      }

      const newEventTypes: EventType[] = integration.eventTypes.filter((eventType) => (
        !leftSelected.find((selectedType) => selectedType._id === eventType._id)
      ));

      /** Update the Menu */
      updateIntegrationEventTypes(newEventTypes);

      /** Remove Selected */
      setLeftSelected([]);
    },
    [ integration.eventTypes, leftSelected, updateIntegrationEventTypes ]
  );


  // ----
  // Internal Data
  // ----
  const selectableEventTypes: EventType[] = not(eventTypes.data || [], integration.eventTypes);


  // ----
  // Component Render
  // ----
  if (!eventTypes.data) {
    return (
      <Box p={4}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Panel header={'Link'}>
        <Detail>
          <TextField
            fullWidth
            name={'overrideCdrAccessUrl'}
            label={'URL Portale Prenotazioni CDR'}
            variant={'outlined'}
            defaultValue={integration.cdrAccessUrl}
            onBlur={handleFieldChange}
          />
        </Detail>
      </Panel>

      <Grid container spacing={2} justify={'center'} alignItems={'flex-start'}>
        <Grid item xs>
          <EventTypesList
            selected={leftSelected}
            title={'Servizi Attivi'}
            eventTypes={arraySort(integration.eventTypes, [ 'masterType', 'label' ])}
            onSelectedChange={setLeftSelected}
          />
        </Grid>
        <Grid item>
          <Grid container direction={'column'} className={classes.actions}>
            <Button
              variant={'outlined'}
              size={'small'}
              className={classes.button}
              disabled={leftSelected.length === 0}
              onClick={handleRemoveEventTypes}
            >
              &gt;
            </Button>
            <Button
              variant={'outlined'}
              size={'small'}
              className={classes.button}
              disabled={rightSelected.length === 0}
              onClick={handleAddEventTypes}
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item xs>
          <EventTypesList
            selected={rightSelected}
            title={'Servizi Disponibili'}
            eventTypes={arraySort(selectableEventTypes, [ 'masterType', 'label' ])}
            onSelectedChange={setRightSelected}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );

};

ServicesTab.displayName = 'ServicesTab';

export default ServicesTab;
