import * as React from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { useDistrictWasteTypes } from '../../../queries/getters/useDistrictWasteTypes';
import { useSingleIntegrationMutation } from '../../../queries/mutations/useSingleIntegrationMutation';

import type { EventType } from '../../../interfaces/EventType';
import type { Integration } from '../../../interfaces/Integration';
import type { WasteType } from '../../../interfaces/WasteType';


/* --------
 * Component Interface
 * -------- */
export interface WasteTypesProps {
  integration: Integration;
}


/* --------
 * Component Definition
 * -------- */
const WasteTypesTab: React.VoidFunctionComponent<WasteTypesProps> = (props) => {

  const { integration } = props;


  // ----
  // Internal States
  // ----
  const [ integrationEvents, setIntegrationEvents ] = React.useState(integration.eventTypes);

  React.useEffect(
    () => setIntegrationEvents(integration.eventTypes),
    [ integration.eventTypes ]
  );


  // ----
  // Queries & Mutations
  // ----
  const wasteTypes = useDistrictWasteTypes(integration._id);
  const mutateIntegration = useSingleIntegrationMutation(integration);


  // ----
  // Helpers
  // ----
  const hasWasteTypeLimit = React.useCallback(
    (eventType: EventType, wasteType: WasteType): boolean => {
      /** Assert wasteTypeLimit is an array */
      if (!Array.isArray(eventType.wasteTypesLimit)) {
        return false;
      }

      /** Find the code */
      return eventType.wasteTypesLimit.indexOf(wasteType.code) !== -1;
    },
    []
  );


  // ----
  // Handlers
  // ----
  const handleToggleWasteTypeLimit = React.useCallback(
    (eventType: EventType, wasteType: WasteType) => () => {
      /** Copy current state */
      const newIntegrationEvents = [ ...integrationEvents ];

      /** Find the event type */
      const integrationEvent = newIntegrationEvents.find(e => e._id === eventType._id);

      if (!integrationEvent) {
        return;
      }

      /** Verify if it must add or remove the code */
      if (hasWasteTypeLimit(eventType, wasteType)) {
        integrationEvent.wasteTypesLimit = integrationEvent.wasteTypesLimit.filter(wtl => wtl !== wasteType.code);
      }
      else {
        integrationEvent.wasteTypesLimit.push(wasteType.code);
      }

      /** Update the state */
      setIntegrationEvents(newIntegrationEvents);

      /** Mutate the integration on database */
      mutateIntegration.mutate({
        action: 'PATCH',
        path  : 'eventTypes',
        data  : newIntegrationEvents
      });
    },
    [ integrationEvents, hasWasteTypeLimit, mutateIntegration ]
  );


  // ----
  // Component Render
  // ----
  if (!wasteTypes.data) {
    return (
      <Box p={4}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Paper>
      <TableContainer component={Box} mt={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align={'center'}>COD</TableCell>
              <TableCell>Descrizione</TableCell>
              {integrationEvents.filter(e => e.hasWasteTypeSubTypes).map(e => (
                <TableCell key={e._id} align={'center'}>
                  {e.displayName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {wasteTypes.data.map(wt => (
              <TableRow key={wt.id}>
                <TableCell align={'center'}>
                  {wt.code}
                </TableCell>
                <TableCell>
                  {wt.description}
                </TableCell>
                {integrationEvents.filter(e => e.hasWasteTypeSubTypes).map(e => (
                  <TableCell key={e._id} align={'center'}>
                    <Checkbox
                      checked={hasWasteTypeLimit(e, wt)}
                      onChange={handleToggleWasteTypeLimit(e, wt)}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

};

WasteTypesTab.displayName = 'WasteTypesTab';

export default WasteTypesTab;
