import * as React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


/* --------
 * Component Interfaces
 * -------- */
export interface PanelProps {
  content?: React.ReactNode;

  header?: React.ReactNode;

  noMargin?: boolean;
}


/* --------
 * Component Definition
 * -------- */
const Panel: React.FunctionComponent<PanelProps> = (props) => {

  const {
    children,
    content,
    header,
    noMargin
  } = props;

  return (
    <Box mb={noMargin ? 0 : 3}>
      <Paper>
        <Box p={2}>
          {header && (
            <Box mb={2}>
              <Typography variant={'h6'}>
                {header}
              </Typography>
            </Box>
          )}
          {content || children}
        </Box>
      </Paper>
    </Box>
  );
};

Panel.displayName = 'Panel';

export default Panel;
