import * as React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';

import { EventType } from '../../../../interfaces/EventType';


/* --------
 * Styles
 * -------- */
const useStyle = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2)
  },

  list: {
    backgroundColor: theme.palette.background.paper
  }
}));


/* --------
 * Component Interfaces
 * -------- */
export interface EventTypesListProps {
  /** Items in list */
  eventTypes: EventType[];

  /** On items changed handler */
  onSelectedChange: React.Dispatch<React.SetStateAction<EventType[]>>;

  /** On sort end handler */
  onSortEnd?: (views: EventType[]) => void;

  /** Selected views */
  selected: EventType[];

  /** Title */
  title: React.ReactNode;
}


/* --------
 * Component Definition
 * -------- */
const EventTypesList: React.FunctionComponent<EventTypesListProps> = (props) => {

  const {
    onSelectedChange: handleSelectedChange,
    onSortEnd,
    selected,
    title,
    eventTypes
  } = props;

  const classes = useStyle();


  // ----
  // Internal Data
  // ----
  const allSelected = !!eventTypes.length && selected.length === eventTypes.length;
  const partialSelected = !!eventTypes.length && !!selected.length && selected.length < eventTypes.length;


  // ----
  // Handler
  // ----
  const handleSelectAll = React.useCallback(
    () => {
      if (allSelected) {
        handleSelectedChange([]);
      }
      else {
        handleSelectedChange(eventTypes);
      }
    },
    [ allSelected, handleSelectedChange, eventTypes ]
  );

  const handleSelectToggleSelect = React.useCallback(
    (view: EventType) => {
      handleSelectedChange((curr) => {
        if (curr.includes(view)) {
          return curr.filter((item) => item !== view);
        }

        return curr.concat([ view ]);
      });
    },
    [ handleSelectedChange ]
  );


  // ----
  // Internal Component
  // ----
  const PlainViewList: React.VFC = () => (
    <List
      className={classes.list}
      dense
      component={'div'}
    >
      {eventTypes.map((event) => (
        <ListItem
          key={event._id}
          button
          onClick={() => handleSelectToggleSelect(event)}
        >
          <ListItemIcon>
            <Checkbox
              disableRipple
              checked={selected.includes(event)}
            />
          </ListItemIcon>
          <ListItemText
            primary={event.displayName}
            secondary={event.masterType}
          />
        </ListItem>
      ))}
    </List>
  );

  const SortableViewList: React.VFC = () => (
    <List className={classes.list} dense component={'div'}>
      {eventTypes.map((event) => (
        <ListItem
          key={event._id}
          button
          onClick={() => handleSelectToggleSelect(event)}
        >
          <ListItemIcon>
            <Checkbox
              disableRipple
              checked={selected.includes(event)}
            />
          </ListItemIcon>
          <ListItemText
            primary={event.displayName}
            secondary={event.masterType}
          />
        </ListItem>
      ))}
    </List>
  );


  // ----
  // Component Render
  // ----
  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={(
          <Checkbox
            onClick={handleSelectAll}
            checked={allSelected}
            indeterminate={partialSelected}
            disabled={eventTypes.length === 0}
          />
        )}
        title={title}
        subheader={`${selected.length} / ${eventTypes.length} selezionat${selected.length === 1 ? 'a' : 'e'}`}
      />

      <Divider />

      {typeof onSortEnd === 'function'
        ? <SortableViewList />
        : <PlainViewList />}
    </Card>
  );
};

EventTypesList.displayName = 'ViewsList';

export default EventTypesList;
