import * as React from 'react';

import arrayMove from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import DragHandleIcon from '@material-ui/icons/DragHandle';

import { makeStyles } from '@material-ui/core/styles';

import { View } from '../../../../interfaces/View';


/* --------
 * Styles
 * -------- */
const useStyle = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2)
  },

  list: {
    backgroundColor: theme.palette.background.paper
  }
}));


/* --------
 * Component Interfaces
 * -------- */
export interface ViewsListProps {
  /** On items changed handler */
  onSelectedChange: React.Dispatch<React.SetStateAction<View[]>>;

  /** On sort end handler */
  onSortEnd?: (views: View[]) => void;

  /** Selected views */
  selected: View[];

  /** Title */
  title: React.ReactNode;

  /** Items in list */
  views: View[];
}


/* --------
 * Side Components
 * -------- */
const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

const SortableListItem = SortableElement<any>(ListItem);

const SortableListContainer = SortableContainer<any>(List);


/* --------
 * Component Definition
 * -------- */
const ViewsList: React.FunctionComponent<ViewsListProps> = (props) => {

  const {
    onSelectedChange: handleSelectedChange,
    onSortEnd,
    selected,
    title,
    views
  } = props;

  const classes = useStyle();


  // ----
  // Internal Data
  // ----
  const allSelected = !!views.length && selected.length === views.length;
  const partialSelected = !!views.length && !!selected.length && selected.length < views.length;


  // ----
  // Handler
  // ----
  const handleSelectAll = React.useCallback(
    () => {
      if (allSelected) {
        handleSelectedChange([]);
      }
      else {
        handleSelectedChange(views);
      }
    },
    [ allSelected, handleSelectedChange, views ]
  );

  const handleSelectToggleSelect = React.useCallback(
    (view: View) => {
      handleSelectedChange((curr) => {
        if (curr.includes(view)) {
          return curr.filter((item) => item !== view);
        }

        return curr.concat([ view ]);
      });
    },
    [ handleSelectedChange ]
  );

  const handleSortEnd = React.useCallback(
    (move: { oldIndex: number, newIndex: number }) => {
      if (typeof onSortEnd !== 'function') {
        return;
      }

      onSortEnd(arrayMove(views.slice(), move.oldIndex, move.newIndex));
    },
    [ onSortEnd, views ]
  );


  // ----
  // Internal Component
  // ----
  const PlainViewList: React.VFC = () => (
    <List
      className={classes.list}
      dense
      component={'div'}
    >
      {views.map((view) => (
        <ListItem
          key={view._id}
          button
          onClick={() => handleSelectToggleSelect(view)}
        >
          <ListItemIcon>
            <Checkbox
              disableRipple
              checked={selected.includes(view)}
            />
          </ListItemIcon>
          <ListItemText
            primary={view.label}
            secondary={view.description}
          />
        </ListItem>
      ))}
    </List>
  );

  const SortableViewList: React.VFC = () => (
    <SortableListContainer className={classes.list} dense component={'div'} onSortEnd={handleSortEnd}>
      {views.map((view, index) => (
        <SortableListItem
          key={view._id}
          button
          index={index}
          onClick={() => handleSelectToggleSelect(view)}
        >
          <ListItemIcon>
            <Checkbox
              disableRipple
              checked={selected.includes(view)}
            />
          </ListItemIcon>
          <ListItemText
            primary={view.label}
            secondary={view.description}
          />
          <ListItemSecondaryAction>
            <DragHandle />
          </ListItemSecondaryAction>
        </SortableListItem>
      ))}
    </SortableListContainer>
  );


  // ----
  // Component Render
  // ----
  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={(
          <Checkbox
            onClick={handleSelectAll}
            checked={allSelected}
            indeterminate={partialSelected}
            disabled={views.length === 0}
          />
        )}
        title={title}
        subheader={`${selected.length} / ${views.length} selezionat${selected.length === 1 ? 'a' : 'e'}`}
      />

      <Divider />

      {typeof onSortEnd === 'function'
        ? <SortableViewList />
        : <PlainViewList />}
    </Card>
  );
};

ViewsList.displayName = 'ViewsList';

export default ViewsList;
