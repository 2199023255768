import * as React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

import { Calendar } from '../../../../interfaces/Calendar';
import { Integration } from '../../../../interfaces/Integration';

import { ask } from '../../../../dialogs/Confirm';

import { useSingleCalendarMutation } from '../../../../queries/mutations/useSingleCalendarMutation';
import CalendarPDF from './CalendarPDF';
import CalendarServices from './CalendarServices';


/* --------
 * Component Styles
 * -------- */
const useStyle = makeStyles((theme) => ({
  dataContainer: {
    padding: theme.spacing(2)
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

  fieldSet: {
    marginBottom            : theme.spacing(2),
    '& > .MuiFormGroup-root': {
      margin: theme.spacing(2, 0)
    }
  }
}));


/* --------
 * Component Interfaces
 * -------- */
export interface CalendarSettingsProps {
  /** Calendar to use */
  calendar: Calendar;

  /** The parent integration */
  integration: Integration;

  /** Handle successfully delete */
  onDelete?: () => void;
}


/* --------
 * Component Definition
 * -------- */
const CalendarSettings: React.FunctionComponent<CalendarSettingsProps> = (props) => {

  const {
    calendar,
    integration,
    onDelete
  } = props;

  const classes = useStyle();


  // ----
  // Internal State
  // ----
  const [ calendarLabel, setCalendarLabel ] = React.useState<string>(calendar.label);


  // ----
  // Data Updated
  // ----
  React.useEffect(
    () => {
      setCalendarLabel(calendar.label);
    },
    [ calendar.label ]
  );


  // ----
  // Calendar Mutation
  // ----
  const calendarMutation = useSingleCalendarMutation(calendar, integration);


  // ----
  // Handlers
  // ----
  const handleCalendarLabelChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setCalendarLabel(value);
    },
    []
  );

  const handleCalendarLabelBlur = React.useCallback(
    () => calendarMutation.mutate({
      action: 'PATCH',
      path  : 'label',
      data  : calendarLabel || calendar.label
    }),
    [ calendar.label, calendarLabel, calendarMutation ]
  );

  const handleCalendarDelete = React.useCallback(
    async () => {
      const result = await (
        ask(
          'Rimuovi Calendario',
          'Vuoi davvero eliminare questo calendario?'
        )
      );

      if (result && typeof onDelete === 'function') {
        onDelete();
      }
    },
    [ onDelete ]
  );


  // ----
  // Component Render
  // ----
  return (
    <Paper className={classes.dataContainer}>
      <FormControl fullWidth component={'fieldset'} className={classes.fieldSet}>
        <FormLabel component={'legend'}>Dettagli Generali</FormLabel>
        <FormGroup style={{ width: '100%' }}>
          <TextField
            fullWidth
            error={!calendarLabel}
            label={'Nome'}
            value={calendarLabel}
            onBlur={handleCalendarLabelBlur}
            onChange={handleCalendarLabelChange}
          />
        </FormGroup>
      </FormControl>
      <Divider className={classes.divider} />
      <FormControl fullWidth component={'div'} className={classes.fieldSet}>
        <FormLabel component={'legend'}>File PDF Collegato</FormLabel>
        <CalendarPDF calendar={calendar} integration={integration} />
      </FormControl>
      <Divider className={classes.divider} />
      <FormControl fullWidth component={'div'} className={classes.fieldSet}>
        <FormLabel component={'legend'}>Materiali e Servizi</FormLabel>
        <CalendarServices calendar={calendar} integration={integration} />
      </FormControl>
      <Divider className={classes.divider} />
      <Box textAlign={'right'}>
        <Button color={'secondary'} variant={'contained'} onClick={handleCalendarDelete}>
          Rimuovi Calendario
        </Button>
      </Box>
    </Paper>
  );
};

CalendarSettings.displayName = 'CalendarSettings';

export default CalendarSettings;
