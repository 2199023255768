import * as React from 'react';

import { useForm, Controller } from 'react-hook-form';

import Button, { ButtonProps } from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import { Calendar } from '../interfaces/Calendar';

import { Integration } from '../interfaces/Integration';

import { useCalendarsMutation } from '../queries/mutations/useCalendarsMutation';


/* --------
 * Internal Types
 * -------- */
export interface AddCalendarProps {
  buttonProps: ButtonProps & { content?: React.ReactText };

  integration: Integration
}


/* --------
 * Styling
 * -------- */
const useStyle = makeStyles((theme) => ({
  form: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2)
    }
  }
}));


/* --------
 * Component Definition
 * -------- */
export const AddCalendar: React.VFC<AddCalendarProps> = (props) => {

  const classes = useStyle();

  const {
    buttonProps: {
      content: buttonContent,
      ...buttonProps
    },
    integration
  } = props;


  // ----
  // Hook Form & Mutations
  // ----
  const calendarsMutation = useCalendarsMutation(integration);
  const { control, handleSubmit, reset } = useForm();


  // ----
  // Internal State
  // ----
  const [ open, setOpen ] = React.useState<boolean>(false);


  // ----
  // Handlers
  // ----
  const handleOpenModal = React.useCallback(
    () => setOpen(true),
    []
  );

  const handleCloseModal = React.useCallback(
    () => {
      setOpen(false);
    },
    []
  );

  const handleResetForm = React.useCallback(
    () => {
      reset();
    },
    [ reset ]
  );

  const handleFormSubmit = React.useCallback(
    (data: Partial<Calendar>) => {
      calendarsMutation.mutate({
        action: 'ADD',
        data  : {
          ...data,
          integration: integration._id
        }
      });
    },
    [ integration._id, calendarsMutation ]
  );

  React.useEffect(
    () => {
      if (calendarsMutation.status === 'success') {
        handleCloseModal();
      }
    },
    [ calendarsMutation.status, handleCloseModal ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      {/* Trigger */}
      <Button {...buttonProps} onClick={handleOpenModal}>{buttonContent ?? 'Open Modal'}</Button>

      {/* Dialog with Form */}
      <Dialog fullWidth maxWidth={'xs'} open={open} onClose={handleCloseModal} onExited={handleResetForm}>
        <DialogTitle>Nuovo Calendario</DialogTitle>
        <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent>
            <DialogContentText>
              Inserisci i dati necessari alla creazione di un nuovo calendario
            </DialogContentText>

            <Controller
              name={'label'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={'Nome'}
                  {...field}
                />
              )}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseModal} disabled={calendarsMutation.isLoading}>
              Annulla
            </Button>
            <Button type={'submit'} variant={'contained'} color={'primary'} disabled={calendarsMutation.isLoading}>
              Inserisci
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
