import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppContext } from '../context/app.context';


/* --------
 * Component Interfaces
 * -------- */
type RouteComponent = React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;


export const routePage: (Component: RouteComponent, title: string) => React.FunctionComponent<RouteComponentProps> = (
  Component, title
) => (props) => {

  const { setPageTitle } = useAppContext();

  React.useEffect(
    () => {
      setPageTitle(title);
    },
    [ setPageTitle ]
  );

  return (
    <Component {...props} />
  );
};
