import * as React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { useAppContext } from './context/app.context';

import { Navbar } from './components/Navbar';
import { Sidebar } from './components/Sidebar';
import { routePage } from './HOC/Page';

import { ApiKeysPage } from './pages/ApiKeys';
import { DistrictsPage } from './pages/Districts';
import { DistrictSingle } from './pages/DistrictSingle';
import { EventTypesPage } from './pages/EventTypes';
import { SignIn } from './pages/Login';


/* --------
 * App Styles
 * -------- */
const useStyle = makeStyles((theme) => {

  const toolbarPadding = JSON.parse(JSON.stringify(theme.mixins.toolbar).replace(/minHeight/g, 'paddingTop'));

  return ({

    root: {
      display: 'flex',
      ...toolbarPadding
    },

    content: {
      flexGrow: 1,
      padding : theme.spacing(3)
    }

  });
});


/* --------
 * App Render
 * -------- */
const App: React.VFC = () => {

  const classes = useStyle();
  const appCtx = useAppContext();


  // ----
  // Loading Pages
  // ----
  if (!appCtx.isLoaded) {
    return null;
  }


  // ----
  // Login Page
  // ----
  if (!appCtx.hasAuth) {
    return <SignIn />;
  }


  // ----
  // Component Render
  // ----
  return (
    <BrowserRouter>

      <Navbar />

      <div className={classes.root}>
        <Sidebar />

        <main className={classes.content}>
          <Switch>
            <Route exact path={'/districts'} component={routePage(DistrictsPage, 'Comuni')} />
            <Route exact path={'/districts/:_id'} component={DistrictSingle} />
            <Route exact path={'/api-keys'} component={routePage(ApiKeysPage, 'API Keys')} />
            <Route exact path={'/event-types'} component={routePage(EventTypesPage, 'Tipologie Servizi')} />
          </Switch>
        </main>
      </div>

    </BrowserRouter>
  );
};

export default App;
