import { useQuery } from 'react-query';

import { GET_INTEGRATIONS } from '../keys';
import { useAxiosRequest } from '../lib/useAxiosRequest';

import { Integration } from '../../interfaces/Integration';


export function useIntegrations() {
  const axiosRequestMaker = useAxiosRequest<Integration[]>({ url: '/integrations' });
  return useQuery(GET_INTEGRATIONS, () => axiosRequestMaker());
}
