import * as React from 'react';

import arraySort from 'array-sort';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import { AddCalendar } from '../../../dialogs/AddCalendar';
import { Calendar } from '../../../interfaces/Calendar';

import { Integration } from '../../../interfaces/Integration';

import { useCalendars } from '../../../queries/getters/useCalendars';
import { useCalendarsMutation } from '../../../queries/mutations/useCalendarsMutation';

import CalendarSettings from './atoms/CalendarSettings';


/* --------
 * Component Styles
 * -------- */
const useStyle = makeStyles((theme) => ({
  dataContainer: {
    padding: theme.spacing(2)
  }
}));


/* --------
 * Component Interfaces
 * -------- */
export interface CalendarsTabProps {
  /** The entire integration object */
  integration: Integration;
}


/* --------
 * Component Definition
 * -------- */
const CalendarsTab: React.FunctionComponent<CalendarsTabProps> = (props) => {

  const {
    integration
  } = props;

  const classes = useStyle();


  // ----
  // Internal State
  // ----
  const [ selectedCalendar, setSelectedCalendar ] = React.useState<Calendar | null>(null);


  // ----
  // Queries
  // ----
  const calendars = useCalendars(integration);
  const calendarsMutation = useCalendarsMutation(integration);


  // ----
  // Handlers
  // ----
  const handleSelectCalendar = React.useCallback(
    (calendar: Calendar) => {
      setSelectedCalendar(calendar);
    },
    []
  );

  const handleDeselectCalendar = React.useCallback(
    () => {
      setSelectedCalendar(null);
    },
    []
  );

  const handleDeleteCalendar = React.useCallback(
    () => {
      if (selectedCalendar) {
        calendarsMutation.mutate({ action: 'DELETE', data: selectedCalendar });
        handleDeselectCalendar();
      }
    },
    [ calendarsMutation, handleDeselectCalendar, selectedCalendar ]
  );

  React.useEffect(
    () => {
      /** If no calendar has been selected abort */
      if (selectedCalendar === null) {
        if (Array.isArray(calendars.data) && calendars.data.length > 0) {
          setSelectedCalendar(calendars.data[0]);
        }
        return;
      }

      /** If data doesn't exists, set selectedCalendar to null */
      if (!Array.isArray(calendars.data)) {
        /** Remove selected calendar */
        setSelectedCalendar(null);
        /** Abort */
        return;
      }

      /** Find calendars using id */
      const sameCalendar = calendars.data.find((calendar) => calendar._id === selectedCalendar._id);

      /** Replace selected calendar if is not the same object */
      if (sameCalendar !== selectedCalendar) {
        setSelectedCalendar(sameCalendar ?? null);
      }
    },
    [ calendars.data, selectedCalendar ]
  );


  // ----
  // Component Render
  // ----
  if (!calendars.data) {
    return (
      <Box p={4}>
        <LinearProgress />
      </Box>
    );
  }


  return (
    <Grid container spacing={2} alignItems={'flex-start'}>
      <Grid item xs={3}>
        <Paper className={classes.dataContainer} elevation={2}>
          {!!calendars.data.length && (
            <React.Fragment>
              <List>
                {arraySort(calendars.data, [ 'label' ]).map((calendar) => (
                  <ListItem
                    key={calendar._id}
                    button
                    selected={selectedCalendar === calendar}
                    onClick={() => handleSelectCalendar(calendar)}
                  >
                    <ListItemText
                      primary={calendar.label}
                      secondary={calendar.dynamic ? 'Dinamico' : 'Statico'}
                    />
                  </ListItem>
                ))}
              </List>
              <Divider style={{ marginBottom: 8 }} />
            </React.Fragment>
          )}
          <AddCalendar
            buttonProps={{
              fullWidth: true,
              variant  : 'contained',
              color    : 'primary',
              content  : 'Aggiungi Calendario'
            }}
            integration={integration}
          />
        </Paper>
      </Grid>
      <Grid item xs={9}>
        {!!selectedCalendar && (
          <CalendarSettings
            calendar={selectedCalendar}
            integration={integration}
            onDelete={handleDeleteCalendar}
          />
        )}
      </Grid>
    </Grid>
  );
};

CalendarsTab.displayName = 'CalendarsTab';

export default CalendarsTab;
