import { useQuery } from 'react-query';

import { GET_DISTRICT_WASTE_TYPES } from '../keys';
import { useAxiosRequest } from '../lib/useAxiosRequest';

import { WasteType } from '../../interfaces/WasteType';


export function useDistrictWasteTypes(_id: string) {
  const axiosRequestMaker = useAxiosRequest<WasteType[]>({
    url: `/integrations/${_id}/yard-waste-type`
  });

  return useQuery([ GET_DISTRICT_WASTE_TYPES, _id ], () => axiosRequestMaker());
}
