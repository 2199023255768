import * as React from 'react';

import { PageHeader } from '../../components/PageHeader';
import { EventTypesTable } from './components/EventTypesTable';


export const EventTypesPage: React.VFC = () => {

  return (
    <React.Fragment>
      <PageHeader
        content={'Tipologie Servizi'}
        subtitle={'Gestisci le tipologie servizi attivabili nei vari Comuni'}
      />

      <EventTypesTable />
    </React.Fragment>
  );
};
