// @ts-ignore
import * as React from 'react';
import ReactDOM from 'react-dom';

import { SnackbarProvider } from 'notistack';

import CssBaseline from '@material-ui/core/CssBaseline';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import { QueryClient, QueryClientProvider } from 'react-query';

import App from './app';
import { AppContextProvider } from './app/context/app.context';
import reportWebVitals from './reportWebVitals';


/* --------
 * React Query Instance
 * -------- */
const queryClient = new QueryClient();


/* --------
 * Theme Definition
 * -------- */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue['600']
    }
  }
});


/* --------
 * Page Render
 * -------- */
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6_000}>
      <AppContextProvider>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <App />
        </QueryClientProvider>
      </AppContextProvider>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
