import { useQuery } from 'react-query';

import { GET_EVENT_TYPES } from '../keys';
import { useAxiosRequest } from '../lib/useAxiosRequest';

import { EventType } from '../../interfaces/EventType';


export function useEventTypes() {
  const axiosRequestMaker = useAxiosRequest<EventType[]>({ url: '/event-types' });
  return useQuery(GET_EVENT_TYPES, () => axiosRequestMaker());
}
