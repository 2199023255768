import { GET_CALENDARS, MUTATE_CALENDARS } from '../keys';
import { useDataMutation } from '../lib/useDataMutation';

import { Calendar } from '../../interfaces/Calendar';
import { Integration } from '../../interfaces/Integration';


export const useCalendarsMutation = (integration: Integration) => (
  useDataMutation<Partial<Calendar>>([ MUTATE_CALENDARS ], {
    namespace  : '/calendars',
    notify     : true,
    parentQuery: [ GET_CALENDARS, integration._id ]
  })
);
