import { useQuery } from 'react-query';

import { GET_INTEGRATIONS } from '../keys';
import { useAxiosRequest } from '../lib/useAxiosRequest';

import { Integration } from '../../interfaces/Integration';


export function useSingleIntegration(_id: string) {
  const axiosRequestMaker = useAxiosRequest<Integration>({
    url: `/integrations/${_id}`
  });
  return useQuery([ GET_INTEGRATIONS, _id ], () => axiosRequestMaker());
}
