import { GET_INTEGRATIONS, MUTATE_INTEGRATIONS } from '../keys';
import { useDataMutation } from '../lib/useDataMutation';

import { Integration } from '../../interfaces/Integration';


export const useIntegrationsMutation = () => (
  useDataMutation<Partial<Integration>>([ MUTATE_INTEGRATIONS ], {
    namespace  : '/integrations',
    notify     : true,
    parentQuery: GET_INTEGRATIONS
  })
);
