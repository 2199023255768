import dayjs from 'dayjs';
import * as React from 'react';

import arraySort from 'array-sort';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircle from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';

import { ask } from '../../../dialogs/Confirm';
import { buildLink } from '../../../HOC/Link';
import { Integration } from '../../../interfaces/Integration';

import { useIntegrations } from '../../../queries/getters/useIntegrations';
import { useIntegrationsMutation } from '../../../queries/mutations/useIntegrationsMutation';


/* --------
 * Component Definition
 * -------- */
export const DistrictsTable: React.VFC = () => {

  const integrations = useIntegrations();
  const mutateIntegrations = useIntegrationsMutation();


  // ----
  // Handlers
  // ----
  const handleRemoveIntegration = React.useCallback(
    async (integration: Integration) => {
      const result = await (
        ask(
          'Rimuovi Integrazione',
          [
            `Vuoi davvero rimuovere l'integrazione con il comune ${integration.name}.`,
            'L\'operazione è irreversibile e tutti i dati collegati a questo comune verranno cancellati.'
          ].join(' ')
        )
      );

      if (result) {
        await mutateIntegrations.mutate({ action: 'DELETE', data: integration });
      }
    },
    [ mutateIntegrations ]
  );


  // ----
  // Component Render
  // ----
  return (
    <Paper>

      <TableContainer component={Box} mt={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align={'center'}>Codice Comune</TableCell>
              <TableCell align={'center'}>Server</TableCell>
              <TableCell align={'center'}>Aggiunto il</TableCell>
              <TableCell align={'center'}>Attivo</TableCell>
              <TableCell style={{ maxWidth: 1 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations.status === 'loading' && (
              <TableRow>
                <TableCell align={'center'} colSpan={6} style={{ borderBottom: 'none' }}>
                  <Box p={2}>
                    <CircularProgress color={'secondary'} size={64} />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {integrations.status === 'success' && !integrations.data.length && (
              <TableRow>
                <TableCell align={'center'} colSpan={6} style={{ borderBottom: 'none' }}>
                  <Box p={2}>
                    <Typography variant={'h5'} color={'textSecondary'}>
                      Nessuna Integrazione
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {integrations.status === 'success' && !!integrations.data.length && (
              arraySort(integrations.data, [ 'name' ]).map((integration) => (
                <TableRow key={integration._id}>
                  <TableCell>{integration.name}</TableCell>
                  <TableCell align={'center'}>{integration.districtCode}</TableCell>
                  <TableCell align={'center'}>{integration.yardServer}</TableCell>
                  <TableCell align={'center'}>{dayjs(integration.createdAt).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align={'center'}>
                    {(
                      integration.isEnabled
                        ? <CheckCircle color={'primary'} />
                        : <HighlightOff color={'secondary'} />
                    )}
                  </TableCell>
                  <TableCell align={'right'} style={{ width: 1, whiteSpace: 'nowrap' }}>
                    <IconButton
                      size={'small'}
                      component={buildLink({ to: `/districts/${integration._id}` })}
                      style={{ marginRight: 8 }}
                    >
                      <EditIcon fontSize={'small'} />
                    </IconButton>
                    <IconButton
                      size={'small'}
                      onClick={() => handleRemoveIntegration(integration)}
                    >
                      <DeleteForeverIcon fontSize={'small'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

};
