import * as React from 'react';

import {
  Link as NativeLink,
  LinkProps as NativeLinkProps
} from 'react-router-dom';


/* --------
 * Component Interfaces
 * -------- */
export interface LinkProps extends NativeLinkProps {

}


/* --------
 * Component Definition
 * -------- */
export function buildLink(props: LinkProps) {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return React.forwardRef((linkProps, _) => (
    <NativeLink
      {...linkProps}
      {...props}
    />
  ));

}
