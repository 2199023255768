import * as React from 'react';
import clsx from 'clsx';

import { matchPath, useLocation } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';

import DomainIcon from '@material-ui/icons/Domain';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { makeStyles } from '@material-ui/core/styles';

import { useAppContext } from '../context/app.context';
import { buildLink } from '../HOC/Link';


/* --------
 * Styling
 * -------- */
const useStyle = makeStyles((theme) => ({

  drawerBase: {
    width     : 280,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },

  drawerClose: {
    width     : 0,
    overflowX : 'hidden',
    transition: theme.transitions.create('width', {
      easing  : theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing  : theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  toolbarPlaceholder: theme.mixins.toolbar

}));


/* --------
 * Menu Definition
 * -------- */
interface MenuItem {
  IconElement: React.ComponentType;

  href: string;

  title: string;
}

const menuElements: { section: string, items: MenuItem[] }[] = [
  {
    section: 'Integrazioni',
    items  : [
      { IconElement: ViewModuleIcon, href: '/', title: 'Panoramica' },
      { IconElement: DomainIcon, href: '/districts', title: 'Comuni' }
    ]
  },
  {
    section: 'Impostazioni',
    items  : [
      { IconElement: LocalOfferIcon, href: '/event-types', title: 'Tipologie Servizi' },
      { IconElement: VpnKeyIcon, href: '/api-keys', title: 'API Keys' }
    ]
  }
];


/* --------
 * Component Definition
 * -------- */
export const Sidebar: React.VFC = () => {

  const classes = useStyle();
  const appCtx = useAppContext();

  const { pathname } = useLocation();

  const isCurrentLocation = React.useCallback(
    (path: string) => {
      return !!matchPath(pathname, {
        path,
        exact: path === '/'
      });
    },
    [ pathname ]
  );


  const drawerClasses = clsx(
    classes.drawerBase,
    {
      [classes.drawerOpen] : appCtx.isSidebarOpen,
      [classes.drawerClose]: !appCtx.isSidebarOpen
    }
  );


  // ----
  // Component Render
  // ----
  return (
    <Drawer
      open={appCtx.isSidebarOpen}
      variant={'permanent'}
      className={drawerClasses}
      classes={{
        paper: drawerClasses
      }}
    >
      <div className={classes.toolbarPlaceholder} />
      {menuElements.map(({ section, items }, ix, arr) => (
        <React.Fragment key={section}>
          <List>
            <ListSubheader>{section}</ListSubheader>
            {items.map(({ IconElement, ...link }) => (
              <ListItem
                key={link.title}
                button
                component={buildLink({ to: link.href })}
                selected={isCurrentLocation(link.href)}
              >
                <ListItemIcon>
                  <IconElement />
                </ListItemIcon>
                <ListItemText primary={link.title} />
              </ListItem>
            ))}
          </List>

          {ix < arr.length - 1 && (
            <Divider />
          )}
        </React.Fragment>
      ))}
    </Drawer>
  );

};
