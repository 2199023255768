export const GET_INTEGRATIONS = 'get-integrations';

export const MUTATE_INTEGRATIONS = 'mutate-integrations';

export const GET_DISTRICT_WASTE_TYPES = 'get-district-waste-types';

export const GET_APIKEYS = 'get-api-keys';

export const MUTATE_APIKEYS = 'mutate-api-keys';

export const GET_VIEWS = 'get-views';

export const MUTATE_VIEWS = 'mutate-views';

export const GET_CALENDARS = 'get-calendars';

export const MUTATE_CALENDARS = 'mutate-calendars';

export const GET_EVENT_TYPES = 'get-event-types';
