import * as React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Panel, { PanelProps } from '../../../../components/Panel';
import { ask } from '../../../../dialogs/Confirm';

import { Integration, Link } from '../../../../interfaces/Integration';
import { useSingleIntegrationMutation } from '../../../../queries/mutations/useSingleIntegrationMutation';


/* --------
 * Component Interfaces
 * -------- */
export interface IntegrationLinksProps {
  /** The integration object */
  integration: Integration;

  /** The link path */
  linkPath: 'systemLinks' | 'termsOfService' | 'usefulLinks';

  /** Panel props */
  panelProps: Omit<PanelProps, 'content'>;
}


/* --------
 * Internal Component
 * -------- */
type LinkState = Pick<Link, 'key' | 'label' | 'url'>;
const LinkController: React.VFC<{ integration: Integration, linkPath: keyof Integration, defaultLink?: Link }> = (
  props
) => {

  const { integration, linkPath, defaultLink } = props;


  // ----
  // Internal State
  // ----
  const [ link, setLink ] = React.useState<LinkState>(defaultLink ?? {
    key  : '',
    label: '',
    url  : ''
  });


  // ----
  // Mutation
  // ----
  const mutateIntegration = useSingleIntegrationMutation(integration);


  // ----
  // Handlers
  // ----
  const handleFieldChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (value !== link[name as keyof LinkState]) {
        setLink((curr) => ({
          ...curr,
          [name]: value
        }));
      }
    },
    [ link ]
  );

  const handleFieldBlur = React.useCallback(
    () => {
      if (link.key === defaultLink?.key && link.label === defaultLink?.label && link.url === defaultLink?.url) {
        return;
      }

      if (defaultLink) {
        mutateIntegration.mutate({
          action: 'PATCH',
          path  : `${linkPath}/${defaultLink._id}`,
          data  : link
        });
      }
      else if (link.key && link.label && link.url) {
        mutateIntegration.mutate({
          action: 'ADD',
          path  : linkPath,
          data  : {
            ...link
          }
        });

        setLink({ key: '', label: '', url: '' });
      }
    },
    [ defaultLink, link, linkPath, mutateIntegration ]
  );

  const handleDeleteLink = React.useCallback(
    async () => {
      if (!defaultLink) {
        return;
      }

      const result = await ask('Rimuovi Link', [
        'Vuoi davvero eliminare il link',
        defaultLink.key,
        'dall\'elenco dei Link per questo comune?'
      ].join(' '));

      if (result) {
        mutateIntegration.mutate({
          action: 'DELETE',
          path  : `${linkPath}/${defaultLink._id}`,
          data  : defaultLink
        });
      }
    },
    [ defaultLink, linkPath, mutateIntegration ]
  );


  // ----
  // Component Render
  // ----
  return (
    <TableRow>
      {([ 'key', 'label', 'url' ] as (keyof LinkState)[]).map((field) => (
        <TableCell key={field}>
          <TextField
            fullWidth
            placeholder={'Nuovo'}
            name={field}
            value={link[field]}
            onBlur={handleFieldBlur}
            onChange={handleFieldChange}
          />
        </TableCell>
      ))}
      <TableCell>
        {!!defaultLink && (
          <IconButton size={'small'} onClick={handleDeleteLink}>
            <DeleteForeverIcon fontSize={'small'} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};


/* --------
 * Component Definition
 * -------- */
const IntegrationLinks: React.FunctionComponent<IntegrationLinksProps> = (props) => {

  const {
    integration,
    linkPath,
    panelProps
  } = props;

  const links = integration[linkPath];


  // ----
  // Component Render
  // ----
  return (
    <Panel
      {...panelProps}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '25%' }}>Chiave</TableCell>
            <TableCell style={{ width: '25%' }}>Etichetta</TableCell>
            <TableCell style={{ width: '50%' }}>URL</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {links.map((link) => (
            <LinkController
              key={link._id}
              integration={integration}
              linkPath={linkPath}
              defaultLink={link}
            />
          ))}

          <LinkController integration={integration} linkPath={linkPath} />
        </TableBody>
      </Table>
    </Panel>
  );
};

IntegrationLinks.displayName = 'IntegrationLinks';

export default IntegrationLinks;
