import { AxiosError, AxiosRequestConfig } from 'axios';
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import { useMutation, useQueryClient, QueryKey, MutationKey } from 'react-query';

import ObjectID from 'bson-objectid';
import deepMerge from 'deepmerge';
import rfdc from 'rfdc';
import { get, set } from 'dot-prop';

import {
  useAxiosEntityMutationRequest,
  EntityMutationRequest,
  EntityBase,
  DataMutationAction
} from './useAxiosRequest';


/* --------
 * Internal Types
 * -------- */
interface UseSingleDataMutation {
  /** Define base axios config */
  baseAxiosConfig?: AxiosRequestConfig;

  /** API Namespace */
  namespace: string;

  /** Set notifications */
  notify?: boolean | SnackbarMessage | ((
    request: EntityMutationRequest,
    state: 'mutating' | 'success' | 'error'
  ) => SnackbarMessage);

  /** Define parent Query to cancel */
  parentQuery?: QueryKey;
}


interface EntityMutationContext {
  /** Mutation action */
  action: DataMutationAction;

  /** Mutating Path */
  path: string;

  /** Current Parent Query Data */
  previousData: unknown;
}


export function useSingleDataMutation<Entity extends EntityBase>(
  entity: Entity,
  mutationKey: MutationKey,
  config: UseSingleDataMutation
) {

  const {
    baseAxiosConfig,
    namespace,
    notify,
    parentQuery
  } = config;

  const cloner = rfdc();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbarKey: SnackbarKey = Array.isArray(mutationKey) ? mutationKey.join('%%') : mutationKey.toString();

  const queryClient = useQueryClient();
  const mutationFn = useAxiosEntityMutationRequest<Entity>(`${namespace}/${entity._id}`, baseAxiosConfig);

  return useMutation<Entity, AxiosError, EntityMutationRequest, EntityMutationContext>({
    mutationKey,
    mutationFn,

    /** While mutating data, perform a optimistic update on parent query */
    onMutate: async ({ action, data, path }) => {
      /** Get raw current data */
      const dataAtPath = path ? get<unknown>(entity, path) : entity;

      /** If parent query doesn't exists, return initial data */
      if (!parentQuery) {
        return { action, path, previousData: dataAtPath };
      }

      /** Show notification */
      if (notify) {
        /** Build notification message */
        let snackbarMessage: SnackbarMessage | null;

        if (typeof notify === 'function') {
          snackbarMessage = notify({ action, data, path }, 'mutating');
        }

        /** Show notification if exists */
        if (snackbarMessage) {
          closeSnackbar(`mutating-${entity._id}-${path}-${snackbarKey}`);
          enqueueSnackbar(snackbarMessage, {
            key: `mutating-${entity._id}-${path}-${snackbarKey}`
          });
        }
      }

      /** Stop any parent query */
      await queryClient.cancelQueries(parentQuery);

      /** Get current parent query value */
      const currentValue = deepMerge<Entity>({}, queryClient.getQueryData<Entity>(parentQuery) || {});
      const currentDataAtPath = get<unknown>(currentValue || {}, path);

      /** Perform optimistic update */
      queryClient.setQueryData<Entity | undefined>(parentQuery, (old) => {
        /** If parent query data doesn't exists, return nothing */
        if (!old) {
          return undefined;
        }

        /** Deep clone old entity to preserve object reference */
        const cloned = cloner(old);

        /** Switch Action */
        switch (action) {
          case 'ADD':
            /** Create a new SubEntity */
            const newSubEntities = {
              ...(data as object),
              _id: new ObjectID().toHexString()
            };

            /** Insert into array */
            const newSubEntitiesArray = Array.isArray(currentDataAtPath) ? currentDataAtPath.slice() : [];
            newSubEntitiesArray.push(newSubEntities);

            /** Set new array at document path */
            set(cloned, path, newSubEntitiesArray);

            return cloned;

          case 'DELETE':
            /** Check container is a valid array */
            if (Array.isArray(currentDataAtPath)) {
              /** Remove the Element */
              const filteredArray = currentDataAtPath.filter((item) => (item as any)._id !== (data as any)._id);

              /** Set the new array at document path */
              set(cloned, path, filteredArray);
            }

            return cloned;

          case 'EDIT':
          case 'PATCH':
            /** If container is an array, must update item */
            if (Array.isArray(currentDataAtPath)) {
              /** Remap item to change element */
              currentDataAtPath.map((item) => (
                (item as any)._id === (data as any)._id
                  ? data
                  : item
              ));
            }
            else {
              set(cloned, path, data);
            }

            return cloned;

          default:
            return cloned;
        }
      });

      return { action, path, previousData: dataAtPath };
    },

    /** On error, roll back state */
    onError: (error, value, ctx) => {
      /** Abort if no parent query exists */
      if (!parentQuery) {
        return;
      }

      /** Get ctx */
      const { action, path, previousData } = ctx || {};

      if (notify && path && action) {
        /** Build notification message */
        let snackbarMessage: SnackbarMessage | null;

        if (notify === true) {
          if (action === 'ADD') {
            snackbarMessage = 'Errore durante l\'Inserimento';
          }
          else if (action === 'EDIT' || action === 'PATCH') {
            snackbarMessage = 'Errore durante la Modifica';
          }
          else {
            snackbarMessage = 'Errore durante l\'Eliminazione';
          }
        }
        else if (typeof notify === 'function') {
          snackbarMessage = notify({
            action,
            data: previousData,
            path
          }, 'error');
        }
        else {
          snackbarMessage = notify;
        }

        /** Show notification if exists */
        if (snackbarMessage) {
          closeSnackbar(`mutating-${entity._id}-${path}-${snackbarKey}`);
          closeSnackbar(`error-${entity._id}-${path}-${snackbarKey}`);
          enqueueSnackbar(snackbarMessage, {
            key    : `error-${entity._id}-${path}-${snackbarKey}`,
            variant: 'error'
          });
        }
      }

      /** Return old data */
      queryClient.setQueryData<Entity | undefined>(parentQuery, (old) => {
        if (!old || !path) {
          return undefined;
        }

        set(old, path, previousData);

        return old;
      });
    },

    onSettled: async (data, error, variables, ctx) => {
      if (parentQuery) {
        await queryClient.invalidateQueries(parentQuery);
      }

      /** If an error occurred, stop */
      if (error) {
        return;
      }

      const { action, path, previousData } = ctx || {};

      /** Show notification */
      if (notify && path && action) {
        /** Build notification message */
        let snackbarMessage: SnackbarMessage | null;

        if (notify === true) {
          if (action === 'ADD') {
            snackbarMessage = 'Inserimento Completato';
          }
          else if (action === 'EDIT' || action === 'PATCH') {
            snackbarMessage = 'Modifica Completata';
          }
          else {
            snackbarMessage = 'Eliminazione Completata';
          }
        }
        else if (typeof notify === 'function') {
          snackbarMessage = notify({
            action,
            data: previousData,
            path
          }, 'success');
        }
        else {
          snackbarMessage = notify;
        }

        /** Show notification if exists */
        if (snackbarMessage) {
          closeSnackbar(`mutating-${entity._id}-${path}-${snackbarKey}`);
          closeSnackbar(`success-${entity._id}-${path}-${snackbarKey}`);
          enqueueSnackbar(snackbarMessage, {
            key    : `success-${entity._id}-${path}-${snackbarKey}`,
            variant: 'success'
          });
        }
      }
    }
  });

}
