import * as React from 'react';

import { PageHeader } from '../../components/PageHeader';

import { AddIntegration } from '../../dialogs/AddIntegration';

import { DistrictsTable } from './components/DistrictsTable';


export const DistrictsPage: React.VFC = () => {

  return (
    <React.Fragment>
      <PageHeader
        content={'Comuni'}
        subtitle={'Abilita, disabilita e gestisci le integrazioni con i Comuni'}
        action={(
          <AddIntegration
            buttonProps={{
              content: 'Aggiungi Comune',
              color  : 'primary',
              variant: 'contained'
            }}
          />
        )}
      />

      <DistrictsTable />
    </React.Fragment>
  );
};
