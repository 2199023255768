import * as React from 'react';
import dayjs from 'dayjs';

import { useSnackbar } from 'notistack';

import arraySort from 'array-sort';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import CheckCircle from '@material-ui/icons/CheckCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOff from '@material-ui/icons/HighlightOff';

import { ask } from '../../../dialogs/Confirm';
import { APIKey } from '../../../interfaces/APIKey';

import { useApiKeys } from '../../../queries/getters/useApiKeys';
import { useApiKeysMutation } from '../../../queries/mutations/useApiKeysMutation';


/* --------
 * Component Definition
 * -------- */
export const ApiKeysTable: React.VFC = () => {

  const apiKeys = useApiKeys();
  const mutateApiKeys = useApiKeysMutation();
  const { enqueueSnackbar } = useSnackbar();


  // ----
  // Handlers
  // ----
  const handleRemoveApiKey = React.useCallback(
    async (apiKey: APIKey) => {
      const result = await (
        ask(
          'Rimuovi Chiave API',
          [
            `Vuoi davvero rimuovere la chiave API che permette l'accesso a ${apiKey.user}.`,
            'ATTENZIONE: L\'operazione è completamente irreversibile. Eliminata la chiave di autenticazione non sarà',
            'possibile crearne un\'altra identica'
          ].join(' '),
          apiKey.user
        )
      );

      if (result) {
        await mutateApiKeys.mutate({ action: 'DELETE', data: apiKey });
      }
    },
    [ mutateApiKeys ]
  );


  const handleCopyApiKeyToClipboard = React.useCallback(
    (apiKey: APIKey) => {
      navigator.clipboard.writeText(apiKey.apiKey).then(() => {
        enqueueSnackbar('API Key copiata negli Appunti');
      });
    },
    [ enqueueSnackbar ]
  );


  // ----
  // Component Render
  // ----
  return (
    <Paper>

      <TableContainer component={Box} mt={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Identificativo</TableCell>
              <TableCell>APIKey</TableCell>
              <TableCell align={'center'}>Ultimo Utilizzo</TableCell>
              <TableCell align={'center'}>Aggiunta il</TableCell>
              <TableCell align={'center'}>Attiva</TableCell>
              <TableCell style={{ maxWidth: 1 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.status === 'loading' && (
              <TableRow>
                <TableCell align={'center'} colSpan={6} style={{ borderBottom: 'none' }}>
                  <Box p={2}>
                    <CircularProgress color={'secondary'} size={64} />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {apiKeys.status === 'success' && !apiKeys.data.length && (
              <TableRow>
                <TableCell align={'center'} colSpan={6} style={{ borderBottom: 'none' }}>
                  <Box p={2}>
                    <Typography variant={'h5'} color={'textSecondary'}>
                      Nessuna Chiave API
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {apiKeys.status === 'success' && !!apiKeys.data.length && (
              arraySort(apiKeys.data, [ 'user' ]).map((apiKey) => (
                <TableRow key={apiKey._id}>
                  <TableCell>{apiKey.user}</TableCell>
                  <TableCell>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <IconButton
                          size={'small'}
                          style={{ marginRight: 8 }}
                          onClick={() => handleCopyApiKeyToClipboard(apiKey)}
                        >
                          <FileCopyIcon fontSize={'small'} />
                        </IconButton>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          noWrap
                          variant={'body2'}
                          style={{
                            display     : 'inline-flex',
                            overflow    : 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                          title={apiKey.apiKey}
                        >
                          <code>{apiKey.apiKey ? `${apiKey.apiKey.slice(0, 40)}...` : 'Generazione Chiave'}</code>
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={'center'}>
                    {apiKey.lastUsedOn !== null
                      ? dayjs(apiKey.lastUsedOn).format('DD/MM/YYYY')
                      : 'Mai'}
                  </TableCell>
                  <TableCell align={'center'}>{dayjs(apiKey.createdAt).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align={'center'}>
                    {(
                      apiKey.isEnabled
                        ? <CheckCircle color={'primary'} />
                        : <HighlightOff color={'secondary'} />
                    )}
                  </TableCell>
                  <TableCell align={'right'} style={{ width: 1, whiteSpace: 'nowrap' }}>
                    <IconButton
                      size={'small'}
                      onClick={() => handleRemoveApiKey(apiKey)}
                    >
                      <DeleteForeverIcon fontSize={'small'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

};
