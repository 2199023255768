import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';

import { useForm, Controller } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddIcon from '@material-ui/icons/Add';

import { makeStyles } from '@material-ui/core/styles';

import { Calendar } from '../interfaces/Calendar';

import { useAxiosRequest } from '../queries/lib/useAxiosRequest';


/* --------
 * Internal Types
 * -------- */
export interface AddCalendarProps {
  calendar: Calendar;

  onComplete?: () => void;
}


/* --------
 * Styling
 * -------- */
const useStyle = makeStyles((theme) => ({
  form: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2)
    }
  }
}));


/* --------
 * Component Definition
 * -------- */
export const AddCalendarService: React.VFC<AddCalendarProps> = (props) => {

  const classes = useStyle();

  const {
    calendar,
    onComplete
  } = props;


  // ----
  // Hook Form & Mutations
  // ----
  const { control, handleSubmit, reset } = useForm();
  const addService = useAxiosRequest({
    baseURL: `calendars/${calendar._id}/add-service`,
    method : 'POST'
  });


  // ----
  // Internal State
  // ----
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoading, setLoading ] = React.useState<boolean>(false);


  // ----
  // Handlers
  // ----
  const handleOpenModal = React.useCallback(
    () => setOpen(true),
    []
  );

  const handleCloseModal = React.useCallback(
    () => {
      setOpen(false);
    },
    []
  );

  const handleResetForm = React.useCallback(
    () => {
      reset();
    },
    [ reset ]
  );

  const handleFormSubmit = React.useCallback(
    async (data: any) => {
      setLoading(true);
      try {
        await addService({
          data: {
            ...data,
            services: []
          }
        });
        setLoading(false);
        if (typeof onComplete === 'function') {
          onComplete();
        }
        handleCloseModal();
      }
      catch (e) {
        setLoading(false);
      }
    },
    [ addService, onComplete, handleCloseModal ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      {/* Trigger */}
      <Fab color={'primary'} onClick={handleOpenModal}>
        <AddIcon />
      </Fab>

      {/* Dialog with Form */}
      <Dialog fullWidth maxWidth={'xs'} open={open} onClose={handleCloseModal} onExited={handleResetForm}>
        <DialogTitle>Nuovo Calendario</DialogTitle>
        <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent>
            <DialogContentText>
              Inserisci i dati necessari alla creazione di un nuovo servizio
            </DialogContentText>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name={'name'}
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      required
                      label={'Nome'}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={'icon'}
                  control={control}
                  defaultValue={'paper'}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>Icona</InputLabel>
                      <Select
                        {...field}
                      >
                        <MenuItem value={'paper'}>Carta</MenuItem>
                        <MenuItem value={'rui'}>Indifferenziato</MenuItem>
                        <MenuItem value={'compostable'}>Organico</MenuItem>
                        <MenuItem value={'plastic'}>Plastica</MenuItem>
                        <MenuItem value={'glass'}>Vetro</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name={'color'}
                  control={control}
                  defaultValue={'#000000'}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      required
                      label={'Colore'}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={'serviceId'}
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      required
                      type={'number'}
                      label={'ID Servizio'}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseModal} disabled={isLoading}>
              Annulla
            </Button>
            <Button type={'submit'} variant={'contained'} color={'primary'} disabled={isLoading}>
              Inserisci
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
