import { GET_APIKEYS, MUTATE_APIKEYS } from '../keys';
import { useDataMutation } from '../lib/useDataMutation';

import { APIKey } from '../../interfaces/APIKey';


export const useApiKeysMutation = () => (
  useDataMutation<Partial<APIKey>>([ MUTATE_APIKEYS ], {
    namespace  : '/api-keys',
    notify     : true,
    parentQuery: GET_APIKEYS
  })
);
