import * as React from 'react';

import MultipleDatesPicker from '@randex/material-ui-multiple-dates-picker';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import TodayIcon from '@material-ui/icons/Today';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { ask } from '../../../../dialogs/Confirm';

import { AddCalendarService } from '../../../../dialogs/AddCalendarService';

import { Calendar, CalendarService } from '../../../../interfaces/Calendar';
import { Integration } from '../../../../interfaces/Integration';
import { useCurrentCalendars } from '../../../../queries/getters/useCalendars';
import { useAxiosRequest } from '../../../../queries/lib/useAxiosRequest';


/* --------
 * Component Interfaces
 * -------- */
export interface CalendarServicesProps {
  /** Calendar to use */
  calendar: Calendar;

  /** The parent integration */
  integration: Integration;
}


/* --------
 * Child Component
 * -------- */
const ServiceRow: React.VFC<{ service: CalendarService, onDelete: (service: CalendarService) => void }> = (props) => {

  const {
    service,
    onDelete
  } = props;

  const {
    color,
    linkedYardService,
    name,
    picks
  } = service;


  // ----
  // Internal State
  // ----
  const [ isCalendarOpen, setIsCalendarOpen ] = React.useState<boolean>(false);


  // ----
  // Memoized Data
  // ----
  const serviceDates: Date[] = React.useMemo(
    () => picks.map(timestamp => new Date(timestamp)),
    [ picks ]
  );


  // ----
  // Handlers
  // ----
  const handleOpenCalendars = React.useCallback(
    () => setIsCalendarOpen(true),
    []
  );

  const handleCloseCalendars = React.useCallback(
    () => setIsCalendarOpen(false),
    []
  );

  const handleDelete = React.useCallback(
    async () => {
      const result = await (
        ask(
          'Rimuovi Servizio',
          'Vuoi davvero eliminare questo servizio dal Calendario?'
        )
      );

      if (result) {
        onDelete(service);
      }
    },
    [ onDelete, service ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell align={'center'}>{color}</TableCell>
        <TableCell>
          <Typography variant={'button'}>{linkedYardService?.id} - {linkedYardService?.code}</Typography><br />
          <Typography variant={'caption'}>{linkedYardService?.description}</Typography>
        </TableCell>
        <TableCell align={'center'}>
          {picks.length}
        </TableCell>
        <TableCell align={'right'} style={{ width: 1, whiteSpace: 'nowrap' }}>
          <IconButton
            size={'small'}
            onClick={handleOpenCalendars}
          >
            <TodayIcon fontSize={'small'} />
          </IconButton>
          <IconButton
            size={'small'}
            onClick={handleDelete}
          >
            <DeleteForeverIcon fontSize={'small'} />
          </IconButton>
        </TableCell>
      </TableRow>

      <MultipleDatesPicker
        readOnly
        onCancel={handleCloseCalendars}
        onSubmit={handleCloseCalendars}
        open={isCalendarOpen}
        selectedDates={serviceDates}
        cancelButtonText={'Chiudi'}
        selectedDatesTitle={'Date Calendarizzate'}
      />
    </React.Fragment>
  );
};


/* --------
 * Component Definition
 * -------- */
const CalendarServices: React.FunctionComponent<CalendarServicesProps> = (props) => {

  const {
    calendar,
    integration
  } = props;


  // ----
  // Calendar Mutation and State
  // ----
  const deleteCalendarService = useAxiosRequest({
    baseURL: `/calendars/${calendar._id}/services`,
    method : 'DELETE'
  });
  const calendarsQuery = useCurrentCalendars(integration);


  // ----
  // Handlers
  // ----
  const handleCalendarServiceDelete = React.useCallback(
    async (service: CalendarService) => {
      await deleteCalendarService({
        url: service._id
      });

      await calendarsQuery.refetch();
    },
    [ calendarsQuery, deleteCalendarService ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>

      <TableContainer component={Box} mb={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align={'center'}>Colore</TableCell>
              <TableCell>Servizio Collegato</TableCell>
              <TableCell align={'center'}>Date Calendarizzate</TableCell>
              <TableCell style={{ maxWidth: 1 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!(calendar.services?.length) && (
              <TableRow>
                <TableCell align={'center'} colSpan={6} style={{ borderBottom: 'none' }}>
                  <Box p={2}>
                    <Typography variant={'h5'} color={'textSecondary'}>
                      Nessun Servizio Aggiunto
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {!!(calendar.services?.length) && (
              calendar.services.map((service) => (
                <ServiceRow
                  key={service._id}
                  service={service}
                  onDelete={handleCalendarServiceDelete}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box textAlign={'right'}>
        <AddCalendarService
          calendar={calendar}
          onComplete={calendarsQuery.refetch}
        />
      </Box>
    </React.Fragment>
  );
};

CalendarServices.displayName = 'CalendarServices';

export default CalendarServices;
