import * as React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


/* --------
 * Component Interfaces
 * -------- */
export interface DetailProps {
  content?: React.ReactNode;

  label?: React.ReactNode;

  noMargin?: boolean;
}


/* --------
 * Component Definition
 * -------- */
const Detail: React.FunctionComponent<DetailProps> = (props) => {

  const {
    children,
    content,
    label,
    noMargin
  } = props;

  return (
    <Box mb={noMargin ? 0 : 2}>
      {label && (
        <Typography variant={'subtitle2'}>
          {label}
        </Typography>
      )}
      {content && (
        <Typography>
          {content}
        </Typography>
      )}
      {children}
    </Box>
  );
};

Detail.displayName = 'Detail';

export default Detail;
