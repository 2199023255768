import { AxiosError, AxiosRequestConfig } from 'axios';
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import { useMutation, useQueryClient, QueryKey, MutationKey } from 'react-query';

import ObjectID from 'bson-objectid';
import deepMerge from 'deepmerge';

import { useAxiosDataMutationRequest, DataMutationRequest, EntityBase, DataMutationAction } from './useAxiosRequest';


/* --------
 * Internal Types
 * -------- */
interface UseDataMutationsConfig<Entity extends EntityBase> {
  /** Define base axios config */
  baseAxiosConfig?: AxiosRequestConfig;

  /** API Namespace */
  namespace: string;

  /** Set notifications */
  notify?: boolean | SnackbarMessage | ((
    request: DataMutationRequest<Entity>,
    state: 'mutating' | 'success' | 'error'
  ) => SnackbarMessage);

  /** Define parent Query to cancel */
  parentQuery?: QueryKey;
}


interface DataMutationContext<Entity extends EntityBase> {
  /** Mutation action */
  action: DataMutationAction;

  /** Mutating Entity */
  entity: Entity;

  /** Current Parent Query Data */
  previousData: Entity[];
}


export function useDataMutation<Entity extends EntityBase>(
  mutationKey: MutationKey,
  config: UseDataMutationsConfig<Entity>
) {

  const {
    baseAxiosConfig,
    namespace,
    notify,
    parentQuery
  } = config;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbarKey: SnackbarKey = Array.isArray(mutationKey) ? mutationKey.join('%%') : mutationKey.toString();

  const queryClient = useQueryClient();
  const mutationFn = useAxiosDataMutationRequest<Entity, Entity>(namespace, baseAxiosConfig);

  return useMutation<Entity, AxiosError, DataMutationRequest<Entity>, DataMutationContext<Entity>>({
    mutationKey,
    mutationFn,

    /** While mutating data, perform a optimistic update on parent query */
    onMutate: async ({ action, data }) => {
      /** If parent query doesn't exists, return empty data */
      if (!parentQuery) {
        return { action, entity: data, previousData: [] };
      }

      /** Show notification */
      if (notify) {
        /** Build notification message */
        let snackbarMessage: SnackbarMessage | null;

        if (typeof notify === 'function') {
          snackbarMessage = notify({ action, data }, 'mutating');
        }

        /** Show notification if exists */
        if (snackbarMessage) {
          enqueueSnackbar(snackbarMessage, {
            key: `mutating-${snackbarKey}`
          });
        }
      }

      /** Stop any parent query */
      await queryClient.cancelQueries(parentQuery);

      /** Get current parent query value */
      const currentValue = queryClient.getQueryData<Entity[]>(parentQuery);

      /** Perform optimistically update */
      queryClient.setQueryData<Entity[]>(parentQuery, (old) => {
        switch (action) {
          case 'ADD':
            /** Set a fake entity id */
            const newEntity = {
              ...data,
              _id: new ObjectID().toHexString()
            };

            /** Insert into new array */
            const newEntitiesArray = Array.isArray(old) ? old.slice() : [];
            newEntitiesArray.push(newEntity);

            /** Return new data */
            return newEntitiesArray;

          case 'DELETE':
            /** Return filtered data */
            return (old || []).filter((oldItem) => oldItem._id !== data._id);

          case 'EDIT':
          case 'PATCH':
            /** If no old exists, return empty data */
            if (!old) {
              return [];
            }

            /** Remap item, editing old entity */
            return old.map((oldItem) => (
              oldItem._id === data._id
                ? deepMerge<Entity>(oldItem, data)
                : oldItem
            ));

          default:
            return currentValue || [];
        }
      });

      return { action, entity: data, previousData: currentValue || [] };
    },

    /** On error, roll back state */
    onError: (error, value, ctx) => {
      /** Abort if no parent query exists */
      if (!parentQuery) {
        return;
      }

      const { action, entity, previousData } = ctx || {};

      /** Show notification */
      if (notify && action && entity) {
        /** Build notification message */
        let snackbarMessage: SnackbarMessage | null;

        if (notify === true) {
          if (action === 'ADD') {
            snackbarMessage = 'Errore durante l\'Inserimento';
          }
          else if (action === 'EDIT' || action === 'PATCH') {
            snackbarMessage = 'Errore durante la Modifica';
          }
          else {
            snackbarMessage = 'Errore durante l\'Eliminazione';
          }
        }
        else if (typeof notify === 'function') {
          snackbarMessage = notify({
            action,
            data: entity
          }, 'error');
        }
        else {
          snackbarMessage = notify;
        }

        /** Show notification if exists */
        if (snackbarMessage) {
          closeSnackbar(`mutating-${snackbarKey}`);
          enqueueSnackbar(snackbarMessage, {
            key    : `error-${snackbarKey}`,
            variant: 'error'
          });
        }
      }

      /** Update old Data */
      queryClient.setQueryData(parentQuery, previousData || []);
    },

    /** Invalidate parent query on data settled */
    onSettled: async (data, error, variables, ctx) => {
      if (parentQuery) {
        await queryClient.invalidateQueries(parentQuery);
      }

      /** If an error occurred, stop */
      if (error) {
        return;
      }

      const { action, entity } = ctx || {};

      /** Show notification */
      if (notify && action && entity) {
        /** Build notification message */
        let snackbarMessage: SnackbarMessage | null;

        if (notify === true) {
          if (action === 'ADD') {
            snackbarMessage = 'Inserimento Completato';
          }
          else if (action === 'EDIT' || action === 'PATCH') {
            snackbarMessage = 'Modifica Completata';
          }
          else {
            snackbarMessage = 'Eliminazione Completata';
          }
        }
        else if (typeof notify === 'function') {
          snackbarMessage = notify({
            action,
            data: entity
          }, 'success');
        }
        else {
          snackbarMessage = notify;
        }

        /** Show notification if exists */
        if (snackbarMessage) {
          closeSnackbar(`mutating-${snackbarKey}`);
          enqueueSnackbar(snackbarMessage, {
            key    : `success-${snackbarKey}`,
            variant: 'success'
          });
        }
      }
    }

  });

}
