import axios, { AxiosRequestConfig, Method } from 'axios';
import * as React from 'react';

import { useAppContext } from '../../context/app.context';


/* --------
 * Internal Types
 * -------- */
type AxiosRequestMaker<Response> = (overrideConfig?: AxiosRequestConfig) => Promise<Response>;

export type EntityBase = {
  /** Entity Primary Key */
  _id?: string | undefined;
  /** Any other keys */
  [key: string]: any
};

export type DataMutationAction = 'ADD' | 'EDIT' | 'PATCH' | 'DELETE';

export type DataMutationRequest<Entity extends EntityBase> = {
  /** Request Action */
  action: DataMutationAction;
  /** Data to send */
  data: Entity;
};

export type EntityMutationRequest = {
  /** Request Action */
  action: DataMutationAction;
  /** Data to Send */
  data: unknown;
  /** Entity SubPath */
  path: string;
};


/* --------
 * Helpers
 * -------- */
function purgeURLPathname(pathname?: string): string {
  if (typeof pathname !== 'string') {
    return '';
  }

  return pathname.replace(/(^\/+)|(\/+$)/, '');
}


/* --------
 * Axios Request Preparation
 * -------- */
export function useAxiosRequest<Response>(baseConfig?: AxiosRequestConfig): AxiosRequestMaker<Response> {

  const appCtx = useAppContext();

  const {
    baseURL,
    ...baseAxiosConfig
  } = baseConfig ?? {};

  /** Build the BaseURL */
  const axiosBaseURL = process.env.NODE_ENV === 'development'
    ? `http://localhost:3000/${purgeURLPathname(baseURL) ?? ''}`
    : `https://api.ecoportale.net/${purgeURLPathname(baseURL) ?? ''}`;

  /** Build the Axios Instance */
  const axiosInstance = axios.create({
    ...baseAxiosConfig,
    baseURL: axiosBaseURL,
    headers: {
      'X-EcoAdminKey': appCtx.adminToken
    }
  });

  /** Render a function used to perform request */
  return (async (overrideConfig): Promise<Response> => {
    /** Get the default configuration */
    const {
      baseURL: _strippedBaseUrl,
      ...defaultAxiosConfiguration
    } = baseConfig ?? {};

    /** Make the Request merging the two configuration */
    const response = await axiosInstance.request<{ data: Response }>({
      ...defaultAxiosConfiguration,
      ...overrideConfig
    });

    return response.data.data;
  });
}


/* --------
 * Axios Mutation Request
 * -------- */
const methodMap: Record<DataMutationAction, Method> = {
  ADD   : 'POST',
  DELETE: 'DELETE',
  EDIT  : 'PUT',
  PATCH : 'PATCH'
};

export function useAxiosDataMutationRequest<Response, Entity extends EntityBase = EntityBase>(
  namespace: string,
  baseConfig?: AxiosRequestConfig
): (request: DataMutationRequest<Entity>, overrideConfig?: AxiosRequestConfig) => Promise<Response> {

  const fetch = useAxiosRequest<Response>(baseConfig);

  return React.useCallback(
    async (request, overrideConfig): Promise<Response> => (
      fetch({
        ...overrideConfig,
        url   : request.action === 'ADD' ? namespace : `${namespace}/${request.data._id}`,
        method: methodMap[request.action],
        data  : request.action !== 'DELETE' ? request.data : undefined
      })
    ),
    [ fetch, namespace ]
  );
}

export function useAxiosEntityMutationRequest<Response>(
  baseUrl: string,
  baseConfig?: AxiosRequestConfig
): (request: EntityMutationRequest, overrideConfig?: AxiosRequestConfig) => Promise<Response> {

  const fetch = useAxiosRequest<Response>({
    baseURL: baseUrl,
    ...baseConfig
  });

  return React.useCallback(
    async (request, overrideConfig) => (
      fetch({
        ...overrideConfig,
        url   : (request.path || '').split('.').join('/'),
        method: methodMap[request.action],
        data  : request.action !== 'DELETE'
          ? (request.action === 'PATCH' ? { data: request.data } : request.data)
          : undefined
      })
    ),
    [ fetch ]
  );

}
