import { useQuery } from 'react-query';

import { GET_APIKEYS } from '../keys';
import { useAxiosRequest } from '../lib/useAxiosRequest';

import { APIKey } from '../../interfaces/APIKey';


export function useApiKeys() {
  const axiosRequestMaker = useAxiosRequest<APIKey[]>({ url: '/api-keys' });
  return useQuery(GET_APIKEYS, () => axiosRequestMaker());
}
