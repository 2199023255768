import * as React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '../../context/app.context';
import { useAxiosRequest } from '../../queries/lib/useAxiosRequest';


/* --------
 * Internal Types
 * -------- */
interface AdminLoginData {
  email: string;

  password: string;
}


/* --------
 * Styles
 * -------- */
const useStyles = makeStyles((theme) => ({
  paper : {
    marginTop    : theme.spacing(8),
    padding      : theme.spacing(6),
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'center'
  },
  avatar: {
    margin         : theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form  : {
    width    : '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));


/* --------
 * Copyright Component
 * -------- */
const Copyright: React.VFC = () => (
  <Typography variant={'body2'} color={'textSecondary'} align={'center'}>
    {'Copyright © '}
    <Link color={'inherit'} href={'http://proedis.net/'}>
      Proedis
    </Link>{' '}
    {new Date().getFullYear()}
    .
  </Typography>
);


/* --------
 * Page Component
 * -------- */
export const SignIn: React.VFC = () => {

  const classes = useStyles();


  // ----
  // Internal State and Hook Form
  // ----
  const { setAdminToken } = useAppContext();
  const loginRequest = useAxiosRequest<string>({ method: 'POST', url: '/auth/admin-access' });

  const [ formData, setFormData ] = React.useState<AdminLoginData>({
    email   : '',
    password: ''
  });
  const [ remember, setRemember ] = React.useState<boolean>(false);
  const [ isLoading, setLoading ] = React.useState<boolean>(false);
  const [ hasLoginError, setHasLoginError ] = React.useState<boolean>(false);


  // ----
  // Handlers
  // ----
  const handleFieldChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((curr) => ({
        ...curr,
        [e.target.name]: e.target.value
      }));
    },
    []
  );

  const handleRememberChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRemember(!!e.target.checked);
    },
    []
  );

  const handleFormSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      /** Prevent default Form Submit */
      e.preventDefault();

      /** Set form as loading */
      setLoading(true);

      /** Perform the Login */
      try {
        const adminKey = await loginRequest({ data: formData });
        setAdminToken(adminKey, remember);
      }
      catch (error) {
        setAdminToken(null);
        setHasLoginError(true);
      }
      finally {
        setLoading(false);
      }
    },
    [ formData, loginRequest, setAdminToken, remember ]
  );


  return (
    <Container component={'main'} maxWidth={'sm'}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component={'h1'} variant={'h5'}>
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
          <TextField
            autoFocus
            required
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            label={'Email'}
            name={'email'}
            type={'email'}
            value={formData.email}
            onChange={handleFieldChange}
          />
          <TextField
            required
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            name={'password'}
            label={'Password'}
            type={'password'}
            value={formData.password}
            onChange={handleFieldChange}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={remember}
                value={'remember'}
                color={'primary'}
                onChange={handleRememberChange}
              />
            )}
            label={'Ricordami'}
          />
          <Button
            fullWidth
            disabled={(!formData.password || !formData.email) || isLoading}
            type={'submit'}
            variant={'contained'}
            color={hasLoginError ? 'secondary' : 'primary'}
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
