import * as React from 'react';

import superbytes from 'superbytes';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import BackupIcon from '@material-ui/icons/Backup';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { makeStyles } from '@material-ui/core/styles';
import { ask } from '../../../../dialogs/Confirm';

import { Calendar } from '../../../../interfaces/Calendar';
import { Integration } from '../../../../interfaces/Integration';
import { useCurrentCalendars } from '../../../../queries/getters/useCalendars';

import { useAxiosRequest } from '../../../../queries/lib/useAxiosRequest';


/* --------
 * Styles
 * -------- */
const useStyle = makeStyles(() => ({

  fileInput: {
    display: 'none'
  }

}));


/* --------
 * Component Interfaces
 * -------- */
export interface CalendarPDFProps {
  /** Parent Calendar */
  calendar: Calendar;

  /** Parent integration */
  integration: Integration;
}


/* --------
 * Component Definition
 * -------- */
const CalendarPDF: React.FunctionComponent<CalendarPDFProps> = (props) => {

  const { calendar, integration } = props;

  const classes = useStyle();


  // ----
  // Internal State
  // ----
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [ isUploading, setIsUploading ] = React.useState<boolean>();
  const [ file, setFile ] = React.useState<File>();


  // ----
  // Internal Hooks
  // ----
  const fileUpload = useAxiosRequest({
    url   : `calendars/${calendar._id}/pdf-file`,
    method: 'POST'
  });
  const calendarsQuery = useCurrentCalendars(integration);


  // ----
  // Handlers
  // ----
  const handleRemoveFile = React.useCallback(
    () => {
      setFile(undefined);
    },
    []
  );

  const handleFileInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;

      if (files && files.length) {
        setFile(files[0]);
      }
      else {
        handleRemoveFile();
      }
    },
    [ handleRemoveFile ]
  );

  const handleOpenFileInput = React.useCallback(
    () => {
      fileInputRef.current?.click();
    },
    []
  );

  const handleUploadFile = React.useCallback(
    async () => {
      if (!file) {
        return;
      }

      /** Perform the request */
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', file);

      await fileUpload({ data: formData });

      await calendarsQuery.refetch();

      setIsUploading(false);
      handleRemoveFile();
    },
    [ file, fileUpload, calendarsQuery, handleRemoveFile ]
  );

  const handleDeleteFile = React.useCallback(
    async () => {
      /** Ask */
      const result = await ask(
        'Rimozione PDF Calendario',
        'Vuoi davvero eliminare il file PDF per questo Calendario?'
      );

      if (result) {
        /** Perform the request */
        setIsUploading(true);

        await fileUpload({ method: 'DELETE' });

        await calendarsQuery.refetch();

        setIsUploading(false);
      }
    },
    [ calendarsQuery, fileUpload ]
  );


  // ----
  // Component Render
  // ----
  if (!calendar.pdfFile) {
    return (
      <Box>
        <Box textAlign={'center'} mt={4} mb={2}>
          <Typography variant={'h5'} color={'textSecondary'}>
            {file ? file.name : 'Nessun File PDF'}
          </Typography>
        </Box>
        <Box textAlign={'center'} mt={2} mb={4}>
          <input
            ref={fileInputRef}
            id={'calendar-pdf-file'}
            className={classes.fileInput}
            type={'file'}
            accept={'application/pdf'}
            onChange={handleFileInputChange}
          />

          <ButtonGroup variant={'contained'}>
            <Button
              disabled={isUploading}
              startIcon={<PictureAsPdfIcon />}
              onClick={handleOpenFileInput}
            >
              {file ? 'Cambia File' : 'Scegli File'}
            </Button>
            {file && (
              <Button disabled={isUploading} color={'primary'} onClick={handleUploadFile}>
                <BackupIcon />
              </Button>
            )}
            {file && (
              <Button disabled={isUploading} color={'secondary'} onClick={handleRemoveFile}>
                <ClearIcon />
              </Button>
            )}
          </ButtonGroup>
        </Box>
      </Box>
    );
  }

  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PictureAsPdfIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={calendar.pdfFile.filename}
          secondary={superbytes(calendar.pdfFile.size)}
        />
        <ListItemSecondaryAction>
          <IconButton target={'_blank'} href={calendar.pdfFile.url} edge={'start'}>
            <OpenInNewIcon />
          </IconButton>
          <IconButton edge={'end'} onClick={handleDeleteFile}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

CalendarPDF.displayName = 'CalendarPDF';

export default CalendarPDF;
