import * as React from 'react';

import * as localforage from 'localforage';


/* --------
 * LocalForage Init
 * -------- */
const localStorage = localforage.createInstance({
  name       : 'EcoAdmin',
  storeName  : 'AppContext',
  description: 'Saved Data for AppContext @ EcoAdmin',
  version    : 1.0
});


/* --------
 * Context Type
 * -------- */
interface AppState {
  /** The current admin token */
  adminToken: string | null;

  /** Check if AppContext has been Loaded */
  isLoaded: boolean;

  /** Check if sidebar is open or not */
  isSidebarOpen: boolean;

  /** Current Page Title */
  pageTitle: string;

  /** The last state update timestamp */
  updatedOn: number;
}

interface AppTools {
  /** Close sidebar menu */
  closeSidebar(): void;

  /** Open sidebar menu */
  openSidebar(): void;

  /** Set the current adminKey */
  setAdminToken(key: string | null, persist?: boolean): void;

  /** Set page title */
  setPageTitle(title: string): void;
}

interface AppVirtuals {
  /** Check if current app has auth or not */
  hasAuth: boolean;
}

export type AppContext = AppState & AppTools & AppVirtuals;


/* --------
 * Context Builder
 * -------- */
const AppCtx = React.createContext<AppContext | null>(null);

export const AppContextProvider: React.FC = (props) => {

  const { children } = props;


  // ----
  // Internal State
  // ----
  const [ appState, setAppState ] = React.useState<AppState>({
    adminToken   : null,
    isLoaded     : false,
    isSidebarOpen: true,
    pageTitle    : 'Eco Admin',
    updatedOn    : Date.now()
  });


  // ----
  // App Init
  // ----
  React.useEffect(
    () => {
      /** Abort if already loaded */
      if (appState.isLoaded) {
        return;
      }

      /** Create an internal async function */
      async function loadPersistentData() {
        try {
          /** Load configuration object from localStorage */
          const {
            isLoaded,
            pageTitle,
            updatedOn,
            ...persistentState
          } = (await localStorage.getItem<AppState>('state')) ?? {};

          /** Merge persistent state with default defined state */
          setAppState((curr) => ({
            ...curr,
            ...persistentState,
            isLoaded: true
          }));
        }
        catch {
          /** If an error occurred, keep default state */
          setAppState((curr) => ({
            ...curr,
            isLoaded: true
          }));
        }
      }

      /** Load data */
      loadPersistentData();
    },
    [ appState.isLoaded ]
  );


  // ----
  // Internal Handlers
  // ----
  const updateAppState = React.useCallback(
    (data: Partial<AppState>, persistent: boolean = true) => {
      /** Set the new state */
      setAppState((curr) => {
        /** Create new State */
        const newState: AppState = { ...curr, ...data, updatedOn: Date.now() };
        /** Update local stored data if need it */
        if (persistent) {
          localStorage.setItem<AppState>('state', newState);
        }
        /** Return the new state */
        return newState;
      });
    },
    []
  );


  // ----
  // AppTools
  // ----
  const closeSidebar = React.useCallback(
    () => updateAppState({ isSidebarOpen: false }),
    [ updateAppState ]
  );

  const openSidebar = React.useCallback(
    () => updateAppState({ isSidebarOpen: true }),
    [ updateAppState ]
  );

  const setAdminToken = React.useCallback(
    (key: string | null, persist: boolean = true) => updateAppState({ adminToken: key }, persist),
    [ updateAppState ]
  );

  const setPageTitle = React.useCallback(
    (title: string) => {
      if (appState.pageTitle !== title) {
        updateAppState({ pageTitle: title });
      }
    },
    [ appState.pageTitle, updateAppState ]
  );


  // ----
  // AppContext
  // ----
  const appContext: AppContext = React.useMemo(
    () => ({
      /** State */
      ...appState,

      /** Methods */
      closeSidebar,
      openSidebar,
      setAdminToken,
      setPageTitle,

      /** Virtuals */
      hasAuth: !!appState.adminToken
    }),
    [ appState, closeSidebar, openSidebar, setAdminToken, setPageTitle ]
  );


  // ----
  // Component Render
  // ----
  return (
    <AppCtx.Provider value={appContext}>
      {children}
    </AppCtx.Provider>
  );

};


export function useAppContext(): AppContext {
  const ctx = React.useContext(AppCtx);

  if (ctx === null || ctx === undefined) {
    throw new Error('Invalid useAppContext hook call');
  }

  return ctx;
}
