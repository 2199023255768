// @ts-ignore
import * as React from 'react';

import { confirmAlert } from 'react-confirm-alert';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';


interface ConfirmModalProps {
  cancel: () => void;

  checker?: string;

  confirm: () => void;

  message: string;

  title: string;
}

const ConfirmModal: React.VFC<ConfirmModalProps> = (props) => {

  const {
    cancel,
    checker,
    confirm,
    message,
    title
  } = props;

  const [ checkerValue, setCheckerValue ] = React.useState<string>('');

  const handleCheckerChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckerValue(e.target.value);
    },
    []
  );

  const isConfirmable = !checker || checkerValue === checker;

  return (
    <Dialog open={true} onClose={cancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
        {checker && (
          <React.Fragment>
            <DialogContentText>
              Per confermare, scrivi <b>{checker}</b>
            </DialogContentText>
            <TextField
              required
              fullWidth
              value={checkerValue}
              placeholder={checker}
              onChange={handleCheckerChange}
            />
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>
          Annulla
        </Button>
        <Button disabled={!isConfirmable} type={'submit'} variant={'contained'} color={'primary'} onClick={confirm}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

};


export function ask(title: string, message: string, checker?: string): Promise<boolean> {
  return new Promise<boolean>((resolve) => (
    confirmAlert({
      onClickOutside  : () => resolve(false),
      onKeypressEscape: () => resolve(false),
      customUI        : ({ onClose }) => {

        const handleModalConfirm = () => {
          onClose();
          return resolve(true);
        };

        const handleModalCancel = () => {
          onClose();
          return resolve(false);
        };

        return (
          <ConfirmModal
            cancel={handleModalCancel}
            confirm={handleModalConfirm}
            message={message}
            title={title}
            checker={checker}
          />
        );
      }
    })
  ));
}
