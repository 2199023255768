import { GET_INTEGRATIONS, MUTATE_INTEGRATIONS } from '../keys';

import { Integration } from '../../interfaces/Integration';
import { useSingleDataMutation } from '../lib/useSingleDataMutation';


export const useSingleIntegrationMutation = (entity: Integration) => (
  useSingleDataMutation<Integration>(
    entity,
    [ MUTATE_INTEGRATIONS, entity._id ],
    {
      namespace  : '/integrations',
      notify     : true,
      parentQuery: [ GET_INTEGRATIONS, entity._id ]
    }
  )
);
