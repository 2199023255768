import * as React from 'react';

import { useForm, Controller } from 'react-hook-form';

import Button, { ButtonProps } from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from '@material-ui/core/styles';
import { Integration } from '../interfaces/Integration';
import { useIntegrationsMutation } from '../queries/mutations/useIntegrationsMutation';


/* --------
 * Internal Types
 * -------- */
export interface AddIntegrationProps {
  buttonProps: ButtonProps & { content?: React.ReactText }
}


/* --------
 * Styling
 * -------- */
const useStyle = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  },
  form     : {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2)
    }
  }
}));


/* --------
 * Component Definition
 * -------- */
export const AddIntegration: React.VFC<AddIntegrationProps> = (props) => {

  const classes = useStyle();

  const {
    buttonProps: {
      content: buttonContent,
      ...buttonProps
    }
  } = props;


  // ----
  // Hook Form & Mutations
  // ----
  const integrationsMutations = useIntegrationsMutation();
  const { control, handleSubmit, reset } = useForm();


  // ----
  // Internal State
  // ----
  const [ open, setOpen ] = React.useState<boolean>(false);


  // ----
  // Handlers
  // ----
  const handleOpenModal = React.useCallback(
    () => setOpen(true),
    []
  );

  const handleCloseModal = React.useCallback(
    () => {
      setOpen(false);
    },
    []
  );

  const handleResetForm = React.useCallback(
    () => {
      reset();
    },
    [ reset ]
  );

  const handleFormSubmit = React.useCallback(
    async (data: Partial<Integration>) => {
      integrationsMutations.mutate({ action: 'ADD', data });
    },
    [ integrationsMutations ]
  );

  React.useEffect(
    () => {
      if (integrationsMutations.status === 'success') {
        handleCloseModal();
      }
    },
    [ handleCloseModal, integrationsMutations.status ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      {/* Trigger */}
      <Button {...buttonProps} onClick={handleOpenModal}>{buttonContent ?? 'Open Modal'}</Button>

      {/* Dialog with Form */}
      <Dialog fullWidth maxWidth={'xs'} open={open} onClose={handleCloseModal} onExited={handleResetForm}>
        <DialogTitle>Nuova Integrazione</DialogTitle>
        <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent>
            <DialogContentText>
              {'Inserisci i dati necessari all\'attivazione di un nuovo comune'}
            </DialogContentText>
            <Controller
              name={'name'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  className={classes.fullWidth}
                  label={'Nome'}
                  {...field}
                />
              )}
            />
            <Controller
              name={'yardServer'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <FormControl className={classes.fullWidth}>
                  <InputLabel>Server</InputLabel>
                  <Select
                    {...field}
                  >
                    <MenuItem value={'dbserv02'}>dbserv02.cantieridigitali.net</MenuItem>
                    <MenuItem value={'dbserv03'}>dbserv03.cantieridigitali.net</MenuItem>
                    <MenuItem value={'web02'}>web02.cantieridigitali.net</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name={'districtCode'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  className={classes.fullWidth}
                  label={'Codice Comune'}
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} disabled={integrationsMutations.isLoading}>
              Annulla
            </Button>
            <Button type={'submit'} variant={'contained'} color={'primary'} disabled={integrationsMutations.isLoading}>
              Inserisci
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
