import { useQuery } from 'react-query';

import { GET_CALENDARS } from '../keys';
import { useAxiosRequest } from '../lib/useAxiosRequest';

import { Calendar } from '../../interfaces/Calendar';
import { Integration } from '../../interfaces/Integration';
import { useCurrentQuery } from '../lib/useCurrentQuery';


export function useCalendars(integration: Integration) {
  const axiosRequestMaker = useAxiosRequest<Calendar[]>({
    url    : '/calendars',
    headers: {
      'X-EcoDistrict': `${integration.yardServer}-${integration.districtCode}`
    }
  });

  return useQuery([ GET_CALENDARS, integration._id ], () => axiosRequestMaker());
}


export function useCurrentCalendars(integration: Integration) {
  return useCurrentQuery('useCurrentCalendars', [ GET_CALENDARS, integration._id ]);
}
