import * as React from 'react';

import arraySort from 'array-sort';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import { useEventTypes } from '../../../queries/getters/useEventTypes';


/* --------
 * Component Definition
 * -------- */
export const EventTypesTable: React.VFC = () => {

  const eventTypes = useEventTypes();


  // ----
  // Component Render
  // ----
  return (
    <Paper>
      <TableContainer component={Box} mt={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align={'center'}>Master Type</TableCell>
              <TableCell align={'center'}>Type</TableCell>
              <TableCell style={{ maxWidth: 1 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {eventTypes.status === 'loading' && (
              <TableRow>
                <TableCell align={'center'} colSpan={6} style={{ borderBottom: 'none' }}>
                  <Box p={2}>
                    <CircularProgress color={'secondary'} size={64} />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {eventTypes.status === 'success' && !eventTypes.data.length && (
              <TableRow>
                <TableCell align={'center'} colSpan={6} style={{ borderBottom: 'none' }}>
                  <Box p={2}>
                    <Typography variant={'h5'} color={'textSecondary'}>
                      Nessuna Tipologia Servizio
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {eventTypes.status === 'success' && !!eventTypes.data.length && (
              arraySort(eventTypes.data, [ 'displayName' ]).map((eventType) => (
                <TableRow key={eventType._id}>
                  <TableCell>{eventType.displayName}</TableCell>
                  <TableCell align={'center'}>{eventType.masterType}</TableCell>
                  <TableCell align={'center'}>{eventType.value}</TableCell>
                  <TableCell />
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

};
