import * as React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

import { makeStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import SignOutIcon from '@material-ui/icons/ExitToApp';

import { useAppContext } from '../context/app.context';


/* --------
 * Styles
 * -------- */
const useStyle = makeStyles((theme) => ({

  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },

  pageTitle: {
    marginLeft: theme.spacing(2),
    flexGrow  : 1
  }

}));


/* --------
 * Component Definition
 * -------- */
export const Navbar: React.VFC = () => {

  const classes = useStyle();

  const { pageTitle, setAdminToken, isSidebarOpen, openSidebar, closeSidebar } = useAppContext();


  // ----
  // Handlers
  // ----
  const handleToggleSidebar = React.useCallback(
    () => {
      if (isSidebarOpen) {
        closeSidebar();
      }
      else {
        openSidebar();
      }
    },
    [ closeSidebar, isSidebarOpen, openSidebar ]
  );

  const handleLogout = React.useCallback(
    () => {
      setAdminToken(null);
    },
    [ setAdminToken ]
  );


  // ----
  // Component Render
  // ----
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton color={'inherit'} onClick={handleToggleSidebar}>
          <MenuIcon />
        </IconButton>

        <Typography variant={'h6'} color={'inherit'} className={classes.pageTitle}>
          {pageTitle}
        </Typography>

        <IconButton color={'inherit'} onClick={handleLogout}>
          <SignOutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
