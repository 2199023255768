import { GET_CALENDARS, MUTATE_CALENDARS } from '../keys';

import { Calendar } from '../../interfaces/Calendar';
import { Integration } from '../../interfaces/Integration';

import { useSingleDataMutation } from '../lib/useSingleDataMutation';


export const useSingleCalendarMutation = (entity: Calendar, integration: Integration) => (
  useSingleDataMutation<Calendar>(
    entity,
    [ MUTATE_CALENDARS, integration._id, entity._id ],
    {
      namespace  : '/calendars',
      notify     : false,
      parentQuery: [ GET_CALENDARS, integration._id ]
    }
  )
);
